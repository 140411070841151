import { DemandSpecialty } from '@common/assigment-machine/constants';
import {
  OpportunityType,
  AssignmentType,
  PLAssignmentRequirement,
  UserReturningInfo,
  ReturningPreference,
} from '@common/assigment-machine/models';
import { Option } from '@common/interfaces';

export interface StatusUpdateResults {
  saved: any[];
  failed: any[];
  ignored: any[];
}

export enum PLAssignmentStatusEnum {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  INITIATED = 'initiated',
  LOCKED = 'locked',
  PENDING = 'pending',
  PL_REJECTED = 'pl_rejected',
  PROPOSED = 'proposed',
  PROVIDER_REJECTED = 'provider_declined',
  REMOVED = 'removed',
  RESERVED = 'reserved',
  CAPACITY_PLANNING = 'capacity_planning',
  CAPACITY_PLANNING_LOCKED = 'capacity_planning_locked',
}

export enum PLDemandTypeEnum {
  ASSESSMENT_ONLY = 'assessment_only',
  DEDICATED_SERVICES = 'dedicated_services',

  ESY = 'esy',
  FLEXIBLE_HOURLY = 'flexible_hourly',
  FTE_CONTRACT_SERVICES = 'fte_contract_services',
  HOURLY = 'hourly',
  SPECIALTY_AAC = 'specialty_aac',
  SPECIALTY_ASL = 'specialty_asl',
  SPECIALTY_BILINGUAL = 'specialty_bilingual',
  SPECIALTY_DHH = 'specialty_dhh',
  SPECIALTY_VI = 'specialty_vi',
}

export enum PLDistrictTypeEnum {
  DISTRICT_BRICK_AND_MORTAR = 'district_brick_and_mortar',
  DISTRICT_VIRTUAL = 'district_virtual',
}
export const ASSIGNMENT_STATUS = {
  [PLAssignmentStatusEnum.ACTIVE]: 'Active',
  [PLAssignmentStatusEnum.COMPLETED]: 'Completed',
  [PLAssignmentStatusEnum.INITIATED]: 'Initiated',
  [PLAssignmentStatusEnum.LOCKED]: 'Locked',
  [PLAssignmentStatusEnum.PENDING]: 'Pending',
  [PLAssignmentStatusEnum.PL_REJECTED]: 'Rejected by PL',
  [PLAssignmentStatusEnum.PROPOSED]: 'Proposed',
  [PLAssignmentStatusEnum.PROVIDER_REJECTED]: 'Declined by Provider',
  [PLAssignmentStatusEnum.REMOVED]: 'Removed',
  [PLAssignmentStatusEnum.RESERVED]: 'Reserved',
  [PLAssignmentStatusEnum.CAPACITY_PLANNING]: 'Proposed - Capacity Planning',
  [PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED]:
    'Locked - Capacity Planning',
};
export const DEMAND_TYPES = {
  [PLDemandTypeEnum.ASSESSMENT_ONLY]: 'Assessment Only',
  [PLDemandTypeEnum.DEDICATED_SERVICES]: 'Dedicated Services',
  [PLDemandTypeEnum.ESY]: 'ESY',
  [PLDemandTypeEnum.FLEXIBLE_HOURLY]: 'Flexible Hourly',
  [PLDemandTypeEnum.FTE_CONTRACT_SERVICES]: 'FTE/Contract Services',
  [PLDemandTypeEnum.HOURLY]: 'Hourly',
  [PLDemandTypeEnum.SPECIALTY_AAC]: 'Specialty - AAC',
  [PLDemandTypeEnum.SPECIALTY_ASL]: 'Specialty - ASL',
  [PLDemandTypeEnum.SPECIALTY_BILINGUAL]: 'Specialty - Bilingual',
  [PLDemandTypeEnum.SPECIALTY_DHH]: 'Specialty - DHH',
  [PLDemandTypeEnum.SPECIALTY_VI]: 'Specialty - VI',
};
export const DISTRICT_TYPES = {
  [PLDistrictTypeEnum.DISTRICT_BRICK_AND_MORTAR]: 'Brick and Mortar',
  [PLDistrictTypeEnum.DISTRICT_VIRTUAL]: 'Virtual',
};
export const CAM_SETTABLE_STATUS = {
  [PLAssignmentStatusEnum.CAPACITY_PLANNING]: 1,
  [PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED]: 1,
  [PLAssignmentStatusEnum.PROPOSED]: 1,
  [PLAssignmentStatusEnum.LOCKED]: 1,
  [PLAssignmentStatusEnum.RESERVED]: 1,
  [PLAssignmentStatusEnum.PL_REJECTED]: 1,
  [PLAssignmentStatusEnum.REMOVED]: 1,
  [PLAssignmentStatusEnum.COMPLETED]: 1,
};
export const STATUS_TRANSITION_MAP = {
  [PLAssignmentStatusEnum.CAPACITY_PLANNING]: {
    [PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED]: 1,
  },
  [PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED]: {
    [PLAssignmentStatusEnum.LOCKED]: 1,
    [PLAssignmentStatusEnum.RESERVED]: 1,
    [PLAssignmentStatusEnum.PL_REJECTED]: 1,
  },
  [PLAssignmentStatusEnum.PROPOSED]: {
    [PLAssignmentStatusEnum.LOCKED]: 1,
    [PLAssignmentStatusEnum.RESERVED]: 1,
    [PLAssignmentStatusEnum.PL_REJECTED]: 1,
  },
  [PLAssignmentStatusEnum.LOCKED]: {
    [PLAssignmentStatusEnum.PROPOSED]: 1,
    [PLAssignmentStatusEnum.RESERVED]: 1,
    [PLAssignmentStatusEnum.PL_REJECTED]: 1,
  },
  [PLAssignmentStatusEnum.RESERVED]: {
    [PLAssignmentStatusEnum.PROPOSED]: 1,
    [PLAssignmentStatusEnum.LOCKED]: 1,
    [PLAssignmentStatusEnum.INITIATED]: 1,
    [PLAssignmentStatusEnum.PL_REJECTED]: 1,
  },
  [PLAssignmentStatusEnum.INITIATED]: {
    [PLAssignmentStatusEnum.PENDING]: 1,
    [PLAssignmentStatusEnum.PROVIDER_REJECTED]: 1,
    [PLAssignmentStatusEnum.REMOVED]: 1,
  },
  [PLAssignmentStatusEnum.PENDING]: {
    [PLAssignmentStatusEnum.ACTIVE]: 1,
    [PLAssignmentStatusEnum.REMOVED]: 1,
  },
  [PLAssignmentStatusEnum.ACTIVE]: {
    [PLAssignmentStatusEnum.COMPLETED]: 1,
  },
  [PLAssignmentStatusEnum.PROVIDER_REJECTED]: {
    [PLAssignmentStatusEnum.PROPOSED]: 1,
    [PLAssignmentStatusEnum.LOCKED]: 1,
    [PLAssignmentStatusEnum.RESERVED]: 1,
  },
  [PLAssignmentStatusEnum.PL_REJECTED]: {},
  [PLAssignmentStatusEnum.REMOVED]: {},
  [PLAssignmentStatusEnum.COMPLETED]: {},
};
export const FILTER_KEY = {
  // Supported by /demand API
  PROVIDER: 'provider',
  ORGANIZATION: 'organization',
  SERVICE_TYPE: 'service_type',
  DEMAND_TYPE: 'demand_type',
  DISTRICT_TYPE: 'district_type',
  SCHOOL_YEAR: 'school_year',
  STATE: 'state',
  PROBABILITY: 'probability',
  PROBABILITY_MAX: 'probability_max',
  START_DATE_GTE: 'start_date__gte',
  START_DATE_LT: 'start_date__lt',
  STAGE: 'stage',

  // NOTE: orgFilters_In 'my_providers' => send logged in cam ID.
  ORG_FILTERS: 'orgFilters_In',

  // values for ORG_FILTERS
  MY_PROVIDERS: 'my_providers',
  UNFILLED_ACCOUNTS: 'unfilled_accounts',
  CURRENT_DEMANDS: 'current_demands',
  INCLUDE_DEMO_DEMANDS: 'include_demo',

  CAM: 'cam',
  UNMET_DEMAND: 'unmet_demand',
  EXCLUDE_EXPIRED: 'exclude_expired',

  STATUS: 'proposal_status',
};

export enum PLRejectedReasonsEnum {
  WEAK_MATCH = 'weak_match',
  WEAK_AVAILABILITY = 'weak_availability',
  MANUAL_OVERRIDE = 'manual_override',
  OTHER = 'other',
}
export const PL_REJECTED_REASONS = {
  [PLRejectedReasonsEnum.WEAK_MATCH]:
    'Clinical requirements not enough of a match',
  [PLRejectedReasonsEnum.WEAK_AVAILABILITY]: 'Availability not a match',
  [PLRejectedReasonsEnum.MANUAL_OVERRIDE]:
    'Manually selected different provider',
  [PLRejectedReasonsEnum.OTHER]: 'Other',
};

export enum ProviderDeclinedReasonsEnum {
  CAPACITY = 'capacity',
  SCHEDULE = 'schedule',
  CASELOAD = 'caseload',
  DISTRICT = 'district',
  NOT_ENOUGH = 'not_enough',
  TOO_MANY = 'too_many',
  OTHER = 'other',
}
export const PROVIDER_REJECTED_REASONS = {
  [ProviderDeclinedReasonsEnum.NOT_ENOUGH]: 'Not enough proposed hours',
  [ProviderDeclinedReasonsEnum.TOO_MANY]: 'Too many proposed hours',
  [ProviderDeclinedReasonsEnum.CAPACITY]:
    'I don’t have capacity for more students',
  [ProviderDeclinedReasonsEnum.SCHEDULE]: 'Schedule mismatch',
  [ProviderDeclinedReasonsEnum.CASELOAD]: 'Caseload mismatch',
  [ProviderDeclinedReasonsEnum.DISTRICT]:
    "I don't want to work at this district",
  [ProviderDeclinedReasonsEnum.OTHER]: 'Other - please specify',
};

export enum RemovedReasonsEnum {
  NO_MATCH = 'no_match',
  DECLINED_BY_PROVIDER = 'declined_by_provider',
  OVER_ASSIGNED = 'over_assigned',
  SCHEDULE_NO_MATCH = 'schedule_no_match',
  CONTRACT_CHANGED = 'contract_changed',
  RESIGNED = 'resigned',
  NO_ANSWER = 'no_answer',
  RECOMMIT_DECLINED = 'recommit_declined',
}

export enum OpportunityStageEnum {
  CLOSED_LOST = 'Closed Lost',
  CLOSED_WON = 'Closed Won',
  CONTRACT_SENT = 'Contract Sent',
  NEGOTIATION = 'Negotiation',
  STUDENT_NUMBERS_CONFIRMED = 'Student Numbers Confirmed',
  WAITING_FOR_BOARD_APPROVAL = 'Waiting for Board Approval',
  INTENDS_TO_CANCEL = 'Intends to Cancel',
}
export const REMOVED_REASONS = {
  [RemovedReasonsEnum.NO_MATCH]: 'Clinical requirements not a match',
  [RemovedReasonsEnum.DECLINED_BY_PROVIDER]: 'Declined by provider',
  [RemovedReasonsEnum.OVER_ASSIGNED]: 'Over assigned',
  [RemovedReasonsEnum.SCHEDULE_NO_MATCH]: 'Schedules not a match',
  [RemovedReasonsEnum.CONTRACT_CHANGED]: 'Contract status changed',
  [RemovedReasonsEnum.RESIGNED]: 'Resigned',
  [RemovedReasonsEnum.NO_ANSWER]: 'Provider did not reply',
  [RemovedReasonsEnum.RECOMMIT_DECLINED]: 'Recommitment declined',
};

export enum CemDemoReasonsEnum {
  SITE_FULLY_STAFFED = 'site_fully_staffed',
  OPPORTUNITY_CHANGED = 'opportunity_changed',
  RESERVED_FOR_SPECIFIC_PROVIDER = 'reserved_for_specific_provider',
  INVALID_DEMAND = 'invalid_demand',
  ON_HOLD = 'on_hold',
}
export const CEM_DEMO_REASONS = {
  [CemDemoReasonsEnum.SITE_FULLY_STAFFED]: 'Site Fully Staffed',
  [CemDemoReasonsEnum.OPPORTUNITY_CHANGED]: 'Opportunity Changed',
  [CemDemoReasonsEnum.RESERVED_FOR_SPECIFIC_PROVIDER]:
    'Reserved for Specific Provider',
  [CemDemoReasonsEnum.INVALID_DEMAND]: 'Invalid Demand',
  [CemDemoReasonsEnum.ON_HOLD]: 'On Hold',
};
export const PROBABILITIES_OPTS: Option[] = [70, 80, 90, 100].map(
  probability => {
    return { value: probability, label: probability.toString() };
  },
);
export const PROBABILITIES_MAX_OPTS: Option[] = [79, 89, 99, 100].map(
  probability_max => {
    return { value: probability_max, label: probability_max.toString() };
  },
);
export const TOAST_TIMEOUT = 5000;
export const GQL_ORGANIZATIONS = `
    query organizations($first: Int!, $offset: Int!, $orderBy: String) {
        organizations(includeProspects: true, opportunityHoldersOnly: true, first: $first, offset: $offset, orderBy: $orderBy) {
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                node {
                    id
                    name
                    state
                    timezone
                }
            }
        }
    }
`;
export const OpportunityTypeToAssignmentTypeMap: Record<
  OpportunityType,
  AssignmentType
> = {
  dedicated: AssignmentType.DEDICATED,
  hourly: AssignmentType.NON_DEDICATED,
  legacy_fte: AssignmentType.LEGACY_FTE,
  unknown: AssignmentType.NON_DEDICATED,
};
// GENERAL NOTE: In the context of this feature code,
// "Opportunity Demand Item" is interchangeable with "Service Group"

export interface PLOrgDemandItem {
  // the org name
  orgName: string;
  // the org id
  uuid: string;
  state: string;
  organizationType?: string;
  csmSlpOt?: string;
  csmPesMhc?: string;
  // hours by service-type
  // e.g. { slt: "15.0", ot: "5.0" }
  hoursByServiceDemand: {};
  hoursByServiceSupply: {};
  opptyDemandList: PLOpptyDemandItem[];
  // tallies
  hoursTotalDemand: number;
  hoursTotalSupply: number;
  fulfillmentPercentNormalized: number;
  cam: string;
  csm: string;
  salesforceId: string;
}

export interface PLOpptyDemandItem {
  opptyName: string;
  uuid: string;
  serviceGroupName: string;
  hours: string;
  unfulfilledHours: string;
  totalHoursProposed: string;
  totalHoursCommitted: string;
  totalHoursCapacityPlanning: string;
  isESY: boolean;
  probability: number;
  pendingCompleteDate: any;
  providerSupplyList?: PLAssignmentProposalItem[];
  providerSupplyListOrig?: PLAssignmentProposalItem[];
  longServiceTypeName: string;
  opportunityLineitemProductCode: string;
  serviceModel: string;
  opportunityStage: string;
  contractDates: string;
  therapyStartDate: string;
  billingStartDate: string;
  lastProviderBillingDate: string;
  opportunitySalesforceId: string;
  isAssessment: boolean;
  specialties: string[];
}

export interface PLOpptyDemandItemRaw {
  uuid: string;
  hours: string;
  unfulfilled_hours: string;
  opportunity_name: string;
  service_group: string;
  total_hours_proposed: string;
  total_hours_committed: string;
  total_hours_capacity_planning: string;
  is_esy: boolean;
  probability: number;
  pending_complete_date: any;
  long_service_type_name: string;
  opportunity_line_item_product_code: string;
  proposals: any[];
  service_model: string;
  opportunity_stage: string;
  contract_dates: string;
  therapy_start_date: string;
  billing_start_date: string;
  last_provider_billing_date: string;
  opportunity_salesforce_id: string;
  is_assessment: boolean;
  specialties: string[];
}
// An assignment proposal expresses an amount of supply (provder hours)
// allocated to meet some opportunity demand

export interface PLAssignmentProposalItem {
  uuid: string;
  opptyDemandUuid: string;
  provider: string;
  providerObj: any;
  providerUuid: string;
  statusLabel: string;
  statusLabelDetail: string;
  statusCode: PLAssignmentStatusEnum;
  supplyHours: string;
  directHours?: string;
  durationHours: string;
  metRequirements?: PLAssignmentRequirement[];
  unmetRequirements?: PLAssignmentRequirement[];
  numRequirements?: number;
  modified?: string;
  separationDate?: string;
  isSeparating?: boolean;
  isSeparated?: boolean;
  isReturning: boolean;
  providerPreferences: Array<ReturningPreference>;
  organizationPreferences: Array<ReturningPreference>;
  startDate?: string;
  endDate?: string;
  showEndDate?: boolean;
  showCompletingOnEndDate?: boolean;
  endDateRaw?: any;
  demandPendingCompleteDateRaw: any;
  unmetRequirementsCount: number;
  _loading?: boolean;
  cemDemoReason?: string;
  blockAssignmentTimeReason?: string;
}

export interface PLAssignmentProposalRaw {
  uuid?: string;
  school_year?: string;
  demand?: string;
  user?: string;
  user_first_name?: string;
  user_last_name?: string;
  status?: PLAssignmentStatusEnum;
  status_detail?: string;
  hours?: string;
  direct_hours?: string;
  fte?: boolean;
  start_date?: string;
  end_date?: string;
  pl_rejected_reason?: string;
  pl_rejected_other_reason?: string;
  provider_rejected_reason?: string;
  provider_rejected_other_reason?: string;
  requirements?: PLAssignmentRequirement[];
  removed_reason?: string;
  include_pending?: boolean;
  modified?: string;
  user_separation_date?: string;
  unmet_requirements_count?: number;
  cem_demo_reason?: string;
  block_assignment_time_reason?: string;
  user_returning_info: UserReturningInfo;
}
// TODO: why is this duped from PLAssignmentProposalItem?

export interface PLAssignmentInterface {
  uuid: string;
  orgName: string;
  orgState: string;
  orgSchoolType: string;
  orgTimezone: string;
  estimatedHours: string | number;
  estimatedHoursDecimal: number;
  schoolYear: string;
  startDate: string;
  endDate: string;
  metRequirements?: PLAssignmentRequirement[];
  unmetRequirements?: PLAssignmentRequirement[];
  requirements?: PLAssignmentRequirement[];
  serviceLines: string[];
  isFTE: boolean;
  isESY: boolean;
  payRate: number;
  modified?: string;
  status?: string;
  statusLabel?: string;
  statusDetail?: string;
  service_model?: string;
  specialties?: DemandSpecialty[];
  isAssessment?: boolean;
  projected_therapy_start_date?: string;
  clinical_success_manager?: string;
  expiration_time?: string;
  main_service_line_title?: string;
  state_full_name?: string;
  specialties_label?: string;
  assignment_type?: string;
  is_on_hold?: boolean;
  on_hold_reason?: string;
  on_hold_as_of?: string;
}

export interface Demand {
  uuid: string;
  is_esy: boolean;
  service_group: string;
  provider_service_group: string;
  hours: string;
  unfulfilled_hours: string;
  opportunity_name: string;
  probability: number;
  total_hours_proposed: string;
  total_hours_capacity_planning: string;
  total_hours_committed: string;
  pending_complete_date: string;
  is_assessment: boolean;
  is_fte: boolean;
  is_dh: boolean;
  is_aac: boolean;
  is_asl: boolean;
  is_dhh: boolean;
  is_vi: boolean;
  service_model: 'DH' | 'SH' | null;
  proposals: any[];
}

export interface OrgDemand {
  uuid: string;
  name: string;
  cam: string;
  demands: Demand[];
}
export interface OrganizationServiceModel {
  acceptable_referral_values: { dedicated: boolean[]; fte: boolean[] };
  demand_service_models: any[];
}
