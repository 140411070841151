import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { AssignmentProposalDataService } from '@app/common/assigment-machine/services';
import { CurrentUserService } from '@modules/user/current-user.service';

import {
  PLMayService,
  PLAssumeLoginService,
  PLGraphQLService,
} from '@root/index';
const GQL_GET_AVAILABILITY = `
query getProviderAvailability($providerId: UUID) {
  availabilityPreference(providerId: $providerId) {
    maxWeeklyHours
  }
}
`;

@Component({
  selector: 'pl-provider-details-header',
  templateUrl: './pl-provider-details-header.component.html',
  styleUrls: ['./pl-provider-details-header.component.less'],
})
export class PLProviderDetailsHeaderComponent implements OnInit, OnChanges {
  @Input() provider: any;
  @Input() userType: string;
  currentUser: any = {};
  availability: any = {};
  userMayAssumeLogin: boolean = false;
  mayViewProvider: boolean = false;
  desiredHours: number;
  assignedHours: number;
  loadingAvailability: boolean;

  constructor(
    private plMay: PLMayService,
    private assumeLoginService: PLAssumeLoginService,
    private store: Store<any>,
    private plGraphQL: PLGraphQLService,
    private plAssignmentProposalService: AssignmentProposalDataService,
    private currentUserService: CurrentUserService,
  ) {}

  private loadAvailability() {
    if (!this.provider?.user) return;
    this.loadingAvailability = true;
    this.plGraphQL
      .query(GQL_GET_AVAILABILITY, {
        providerId: this.provider.user,
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res.availabilityPreference)
          this.desiredHours = res.availabilityPreference.maxWeeklyHours;
        this.loadingAvailability = false;
      });
  }

  private loadAssignments() {
    if (!this.provider?.user) return;
    this.plAssignmentProposalService
      .list({
        provider: this.provider.user,
      })
      .subscribe(assignments => {
        this.assignedHours = assignments.results.reduce((acc, assignment) => {
          let [hours, minutes] = assignment.hours.split(':').map(Number);
          return acc + hours + minutes / 60;
        }, 0);
      });
  }

  private getStatus() {
    switch (this.provider?.provider_sub_status) {
      case 'Onboarding':
      case 'Pending Readiness Check':
        return 'Onboarding';
      case 'Ready for Placement':
      case 'Providing Services':
      case 'SLP/OT Assessment Only':
        return 'Active';
      case 'Short Term Leave/On Hold':
        return 'On Hold';
      default:
        return 'Inactive';
    }
  }

  assumeUser() {
    this.assumeLoginService.assume(this.provider.email);
  }

  get showAssumeButton() {
    return this.userMayAssumeLogin && this.provider && this.provider.user;
  }

  checkPrivileges() {
    if (
      this.currentUser &&
      this.currentUser.uuid &&
      this.provider &&
      this.provider.user
    ) {
      this.mayViewProvider =
        this.provider.user === this.currentUser.uuid ||
        this.plMay.isAdminType(this.currentUser) ||
        (this.currentUser.xGlobalPermissions &&
          this.currentUser.xGlobalPermissions.viewPersonnel)
          ? true
          : false;
    }
  }

  ngOnChanges(): void {
    if (this?.provider?.user) {
      this.loadAvailability();
      this.loadAssignments();
    }
  }

  ngOnInit() {
    this.currentUserService.getCurrentUser().subscribe((user: any) => {
      this.userMayAssumeLogin = this.plMay.assumeLogin(user);
      this.currentUser = user;
      this.checkPrivileges();
    });
    this.loadAvailability();
    this.loadAssignments();
  }
}
