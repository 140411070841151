import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CanDeactivateGuard } from '@common/can-deactivate-guard.service';

import { PlLocationMatchingComponent } from '@modules/add-referrals/pl-location-matching/pl-location-matching.component';
import { ROUTING } from './common/constants';
import { PLAddReferralsComponent } from './modules/add-referrals/pl-add-referrals.component';
import { PLProviderMatchingComponent } from './modules/add-referrals/pl-provider-matching/pl-provider-matching.component';
import { PLReferralsConfirmationComponent } from './modules/add-referrals/pl-referrals-confirmation/pl-referrals-confirmation.component';
import { PLSelectLocationComponent } from './modules/add-referrals/pl-select-location/pl-select-location.component';
import { PLUploadReferralsComponent } from './modules/add-referrals/pl-upload-referrals/pl-upload-referrals.component';
import { PLAssignmentManagerRouteGuardService } from './modules/assignment-manager/pl-assignment-manager-routeguard.service';
import { PLAssignmentManagerComponent } from './modules/assignment-manager/pl-assignment-manager.component';
import { PLProviderAssignmentsComponent } from './modules/assignment-manager/pl-provider-assignments.component';
import { PLAssignmentPreferencesComponent } from './modules/assignment-preferences/pl-assignment-preferences/pl-assignment-preferences.component';
import { PLIntentToReturnComponent } from './modules/assignment-preferences/pl-intent-to-return/pl-intent-to-return.component';
import { PLCamDashboardCurrentUserResolverService } from './modules/cam-dashboard/pl-cam-dashboard/pl-cam-dashboard-current-user-resolver.service';
import { PLCamDashboardComponent } from './modules/cam-dashboard/pl-cam-dashboard/pl-cam-dashboard.component';
import { PLCamLocationsSchedulingStatusComponent } from './modules/cam-dashboard/pl-cam-locations-scheduling-status/pl-cam-locations-scheduling-status.component';
import { PLClientMergePermissionsService } from './modules/client-merge/pl-client-merge-permissions.service';
import { PLClientReferralSaveComponent } from './modules/client-referral-save/pl-client-referral-save/pl-client-referral-save.component';
import { PLClientReferralManagerComponent } from './modules/client-referrals/pl-client-referral-manager/pl-client-referral-manager.component';
import { PLClientReferralOpenComponent } from './modules/client-referrals/pl-client-referral-open/pl-client-referral-open.component';
import { PLClientReferralsComponent } from './modules/client-referrals/pl-client-referrals/pl-client-referrals.component';
import { PLClientContactEnableLoginComponent } from './modules/clients/pl-client-contact-enable-login/pl-client-contact-enable-login.component';
import { PLClientContactEnableSMSComponent } from './modules/clients/pl-client-contact-enable-sms/pl-client-contact-enable-sms.component';
import { PLCustomerDashboardComponent } from './modules/customer-dashboard/pl-customer-dashboard/pl-customer-dashboard.component';
import { PLDashboardComponent } from './modules/dashboard/pl-dashboard/pl-dashboard.component';
import { ConfigComponent } from './modules/dev/config/config.component';
import { plIconsDemoComponent } from './modules/dev/pl-icons/pl-icons-demo.component';
import { PLHomeComponent } from './modules/home/pl-home/pl-home.component';
import { PLISADashboardComponent } from './modules/isa/pl-isa-dashboard/pl-isa-dashboard.component';
import { PlISADashboardViewGuard } from './modules/isa/pl-isa-dashboard-view.guard';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { PLLiveagentPostchatPageComponent } from './modules/pl-liveagent-pages/pl-liveagent-postchat-page/pl-liveagent-postchat-page.component';
import { PLLiveagentPrechatPageComponent } from './modules/pl-liveagent-pages/pl-liveagent-prechat-page/pl-liveagent-prechat-page.component';
import { PLFormAssemblyPageComponent } from './modules/pl-third-party-pages/pl-form-assembly-page/pl-form-assembly-page.component';
import { PLProviderPreagreementComponent } from './modules/provider-onboarding/pl-provider-preagreement/pl-provider-preagreement.component';
import { PLProviderPreagreementW2Component } from './modules/provider-onboarding/pl-provider-preagreement-w2/pl-provider-preagreement-w2.component';
import { PLProviderComponent } from './modules/providers/pl-provider/pl-provider.component';
import { PLProviderAvailabilityComponent } from './modules/providers/pl-provider-availability/pl-provider-availability.component';
import { PLProviderCaseloadRouteGuardService } from './modules/providers/pl-provider-caseload/pl-provider-caseload-routeguard.service';
import { PLProviderCaseloadComponent } from './modules/providers/pl-provider-caseload/pl-provider-caseload.component';
import { PLProviderLocationsComponent } from './modules/providers/pl-provider-locations/pl-provider-locations.component';
import { PLProviderPayRatesRouteGuardService } from './modules/providers/pl-provider-pay-rates/pl-provider-pay-rates-routeguard.service';
import { PLProviderPayRatesComponent } from './modules/providers/pl-provider-pay-rates/pl-provider-pay-rates.component';
import { PLProviderProfileAssignmentsComponent } from './modules/providers/pl-provider-profile-assignments/pl-provider-profile-assignments.component';
import { PlProviderQualificationsComponent } from './modules/providers/pl-provider-qualifications/pl-provider-qualifications.component';
import { PLProvidersListComponent } from './modules/providers/pl-providers-list/pl-providers-list.component';
import { PLServiceSaveComponent } from './modules/service-save/pl-service-save/pl-service-save.component';
import { PLServiceSaveAssignComponent } from './modules/service-save/pl-service-save-assign/pl-service-save-assign.component';
import { PLServiceSaveClientDetailsComponent } from './modules/service-save/pl-service-save-client-details/pl-service-save-client-details.component';
import { PLServiceSaveDocumentationComponent } from './modules/service-save/pl-service-save-documentation/pl-service-save-documentation.component';
import { PLServiceSaveIdentifyComponent } from './modules/service-save/pl-service-save-identify/pl-service-save-identify.component';
import { PLServiceSaveServiceDetailsComponent } from './modules/service-save/pl-service-save-service-details/pl-service-save-service-details.component';
import { plLogoutComponent } from './modules/user/pl-logout/pl-logout.component';
import { UserAuthGuardService } from './modules/user/user-auth-guard.service';
import { UserCanAccessAssignmentsAuthGuardService } from './modules/user/user-can-access-assignments-auth-guard.service';
import { UserCanAccessClientReferralManagerAuthGuardService } from './modules/user/user-can-access-client-referral-manager-auth-guard.service';
import { UserCanAccessPLProviderLandingAuthGuardService } from './modules/user/user-can-access-pl-provider-landing-auth-guard.service';
import { PLUsersComponent } from './modules/users/pl-users/pl-users.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'add-referrals',
          component: PLAddReferralsComponent,
          canActivate: [UserAuthGuardService],
          canDeactivate: [CanDeactivateGuard],
          children: [
            { path: '', redirectTo: 'select-location', pathMatch: 'full' },
            { path: 'select-location', component: PLSelectLocationComponent },
            { path: 'upload-referrals', component: PLUploadReferralsComponent },
            {
              path: 'location-matching',
              component: PlLocationMatchingComponent,
            },
            {
              path: 'provider-matching',
              component: PLProviderMatchingComponent,
            },
            {
              path: 'referrals-confirmation',
              component: PLReferralsConfirmationComponent,
            },
          ],
          data: { title: 'Upload Referrals - Clients' },
        },
        {
          path: 'assignment-preferences',
          component: PLAssignmentPreferencesComponent,
          canActivate: [UserAuthGuardService],
        },
        {
          path: 'assignment-preferences/intent-to-return',
          component: PLIntentToReturnComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Intent to Return' },
        },
        {
          path: 'client-referrals',
          component: PLClientReferralsComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Client Referrals' },
          children: [
            { path: '', redirectTo: 'manager', pathMatch: 'full' },
            {
              path: 'open',
              component: PLClientReferralOpenComponent,
              data: { title: 'Open Referrals - Client Referrals' },
            },
            {
              path: 'manager',
              component: PLClientReferralManagerComponent,
              canActivate: [UserCanAccessClientReferralManagerAuthGuardService],
              data: { title: 'Manager - Client Referrals' },
            },
          ],
        },
        {
          path: 'client-referral-save',
          component: PLClientReferralSaveComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Add Client Referral - Clients' },
        },
        {
          path: 'cam-dashboard',
          redirectTo: 'cam-dashboard/overview',
          pathMatch: 'full',
        },
        {
          path: 'cam-dashboard/overview',
          component: PLCamDashboardComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Overview - Empowerment Manager Dashboard' },
          resolve: { currentUser: PLCamDashboardCurrentUserResolverService },
        },
        {
          path: 'cam-dashboard/locations-scheduling-status',
          component: PLCamLocationsSchedulingStatusComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Scheduling Status - Empowerment Manager Dashboard' },
        },
        {
          path: 'service-save',
          component: PLServiceSaveComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Service Save' },
          children: [
            { path: '', redirectTo: 'identify', pathMatch: 'full' },
            { path: 'identify', component: PLServiceSaveIdentifyComponent },
            {
              path: 'documentation',
              component: PLServiceSaveDocumentationComponent,
            },
            {
              path: 'client-details',
              component: PLServiceSaveClientDetailsComponent,
            },
            {
              path: 'service-details',
              component: PLServiceSaveServiceDetailsComponent,
            },
            { path: 'assign', component: PLServiceSaveAssignComponent },
          ],
        },
        {
          path: 'providers',
          component: PLProvidersListComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Providers' },
        },
        {
          path: 'provider/:id',
          component: PLProviderComponent,
          canActivate: [UserAuthGuardService],
          data: { title: ROUTING.DYNAMIC },
          children: [
            { path: '', redirectTo: 'overview', pathMatch: 'full' },
            // to catch old links to account-settings
            {
              path: 'account-settings',
              redirectTo: 'overview',
              pathMatch: 'full',
            },

            {
              path: 'overview',
              loadChildren: () =>
                import(
                  './modules/providers/pl-provider-details/pl-provider-details.module'
                ).then(m => m.PLProviderDetailsModule),
            },
            {
              path: 'caseload',
              component: PLProviderCaseloadComponent,
              canActivate: [PLProviderCaseloadRouteGuardService],
            },
            {
              path: 'qualifications',
              component: PlProviderQualificationsComponent,
            },
            { path: 'locations', component: PLProviderLocationsComponent },
            {
              path: 'assignments',
              component: PLProviderProfileAssignmentsComponent,
            },
            {
              path: 'availability',
              component: PLProviderAvailabilityComponent,
            },
            {
              path: 'provider-pay-rates',
              component: PLProviderPayRatesComponent,
              canActivate: [PLProviderPayRatesRouteGuardService],
            },
          ],
        },
        {
          path: 'dashboard',
          component: PLCustomerDashboardComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Dashboard' },
        },
        {
          path: 'isas-dashboard',
          component: PLISADashboardComponent,
          canActivate: [UserAuthGuardService, PlISADashboardViewGuard],
          data: { title: 'ISAs Dashboard' },
        },
        {
          path: 'agreement/:id',
          component: PLProviderPreagreementComponent,
          data: { title: 'Agreement' },
        },
        {
          path: 'agreementw2/:id',
          component: PLProviderPreagreementW2Component,
          data: { title: 'Agreement' },
        },
        {
          path: 'pay-rates',
          component: PLProviderPayRatesComponent,
          data: { title: 'Pay Rates' },
        },
        {
          path: 'provider-pay-rates',
          component: PLProviderPayRatesComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Provider Pay Rates' },
        },
        {
          path: 'home',
          component: PLHomeComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Home' },
        },
        {
          path: 'landing',
          component: PLDashboardComponent,
          canActivate: [
            UserAuthGuardService,
            UserCanAccessPLProviderLandingAuthGuardService,
          ],
          data: { title: 'Landing' },
        },
        {
          path: 'launch',
          canActivate: [UserAuthGuardService],
          loadChildren: () =>
            import('./modules/launcher/index').then(m => m.PLLauncherModule),
        },
        {
          path: 'users',
          component: PLUsersComponent,
          canActivate: [UserAuthGuardService],
          data: { title: 'Users' },
        },
        {
          path: 'assignments',
          component: PLProviderAssignmentsComponent,
          canActivate: [
            UserAuthGuardService,
            UserCanAccessAssignmentsAuthGuardService,
          ],
          data: { title: 'Assignments - Schedule' },
        },
        // ----------------------------------------
        // CAM routes
        // ----------------------------------------
        {
          path: 'assignment-manager',
          component: PLAssignmentManagerComponent,
          canActivate: [
            UserAuthGuardService,
            PLAssignmentManagerRouteGuardService,
          ],
          data: {
            title: 'Assignment Manager - Empowerment Manager Dashboard',
          },
        },
        // ----------------------------------------
        // Other routes
        // ----------------------------------------
        { path: 'dev/icons', component: plIconsDemoComponent },
        { path: 'config', component: ConfigComponent },
        {
          path: 'liveagent-prechat',
          component: PLLiveagentPrechatPageComponent,
        },
        {
          path: 'liveagent-postchat',
          component: PLLiveagentPostchatPageComponent,
        },
        { path: 'form-assembly', component: PLFormAssemblyPageComponent },
        { path: 'logout', component: plLogoutComponent },
        // Lazy loading
        // ----------------------------------------
        // Provider scheduling and documentation
        // ----------------------------------------
        {
          path: 'documentation-assistant',
          canActivate: [UserAuthGuardService],
          loadChildren: () =>
            import(
              './modules/workflow-manager/workflows/invoice-documentation/pl-ida.module'
            ).then(m => m.PLIDAModule),
        },
        {
          path: 'client',
          canActivate: [UserAuthGuardService],
          data: { title: ROUTING.DYNAMIC },
          loadChildren: () =>
            import('./modules/clients/index').then(m => m.PLClientsModule),
        },
        {
          path: 'client',
          data: { title: ROUTING.DYNAMIC },
          children: [
            {
              path: 'contact/enable_sms',
              component: PLClientContactEnableSMSComponent,
              data: { title: 'Enable SMS' },
            },
            {
              path: 'contact/enable_login',
              component: PLClientContactEnableLoginComponent,
              data: { title: 'Enable Account Access' },
            },
          ],
        },
        {
          path: 'location',
          canActivate: [UserAuthGuardService],
          loadChildren: () =>
            import('./modules/locations/index').then(m => m.PLLocationsModule),
        },
        {
          path: 'organization',
          canActivate: [UserAuthGuardService],
          loadChildren: () =>
            import('./modules/organizations/index').then(
              m => m.PLOrganizationsModule,
            ),
        },
        {
          path: 'provider-onboarding',
          canActivate: [UserAuthGuardService],
          loadChildren: () =>
            import('./modules/provider-onboarding/index').then(
              m => m.PLProviderOnboardingModule,
            ),
        },
        {
          path: 'schedule',
          loadChildren: () =>
            import('./modules/schedule/schedule.module').then(
              m => m.PLScheduleModule,
            ),
        },
        {
          path: 'billing',
          loadChildren: () =>
            import('./modules/billing/billing.module').then(
              m => m.PLBillingModule,
            ),
        },
        {
          path: 'client-merge',
          canActivate: [UserAuthGuardService, PLClientMergePermissionsService],
          loadChildren: () =>
            import('./modules/client-merge').then(m => m.PLClientMergeModule),
        },
        {
          path: 'availability',
          canActivate: [UserAuthGuardService],
          data: { title: 'Availability - Schedule' },
          loadChildren: () =>
            import('./modules/schedule/index').then(
              m => m.PLScheduleAvailabilityModule,
            ),
        },
        {
          path: 'provider-profile',
          loadChildren: () =>
            import(
              './modules/providers/pl-provider-v2/pl-provider-v2.module'
            ).then(m => m.PLProviderV2Module),
        },
        // redirect to old URL
        { path: 'clients', redirectTo: 'client/list' },
        { path: 'user-save', redirectTo: 'users', pathMatch: 'full' },
        { path: 'locations', redirectTo: 'location/list' },
        { path: 'locations/organizations', redirectTo: 'organization/list' },
        { path: 'contact/enable_sms', redirectTo: 'client/contact/enable_sms' }, // Double Check
        {
          path: 'contact/enable_login',
          redirectTo: 'client/contact/enable_login',
        }, // Double Check

        { path: '', redirectTo: 'home', pathMatch: 'full' },
        { path: '**', component: PageNotFoundComponent },
        { path: 'not-found', component: PageNotFoundComponent },
      ],
      { relativeLinkResolution: 'legacy' },
    ),
  ],
  exports: [RouterModule],
  providers: [
    PlISADashboardViewGuard,
    PLProviderPayRatesRouteGuardService,
    PLProviderCaseloadRouteGuardService,
  ],
})
export class AppRoutingModule {}
