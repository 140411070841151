import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import {
  AssignmentProposal,
  PLAssignmentRequirement,
} from '@common/assigment-machine/models';
import { AssignmentMarketplaceDemand } from '@common/assigment-machine/models/assignment-marketplace.model';
import { SPECIALTIES_LONG_NAME } from '@common/assigment-machine/specialties.constants';
import { IS_NOT_FY23_PROVIDER } from '@common/helpers';
import { Option } from '@common/interfaces';
import { PLUtilService, PLSchoolYearsService } from '@common/services';
import {
  PLHttpService,
  PLApiUsStatesService,
  PLTimezoneService,
} from '@root/index';
import {
  PLAssignmentStatusEnum,
  ASSIGNMENT_STATUS,
  CAM_SETTABLE_STATUS,
  FILTER_KEY,
  DEMAND_TYPES,
  DISTRICT_TYPES,
  PL_REJECTED_REASONS,
  PROVIDER_REJECTED_REASONS,
  REMOVED_REASONS,
  CEM_DEMO_REASONS,
  STATUS_TRANSITION_MAP,
  PROBABILITIES_OPTS,
  PROBABILITIES_MAX_OPTS,
  GQL_ORGANIZATIONS,
  StatusUpdateResults,
  TOAST_TIMEOUT,
  OrganizationServiceModel,
  PLAssignmentProposalRaw,
  PLAssignmentProposalItem,
  PLOrgDemandItem,
  PLOpptyDemandItem,
  PLOpptyDemandItemRaw,
} from './pl-assignment-manager.model';

import { PLAssignmentProposalItemService } from './pl-assignment-proposal-item.service';

dayjs.extend(durationPlugin);
@Injectable()
export class PLAssignmentManagerService {
  ASSIGNMENT_STATUS: any = ASSIGNMENT_STATUS;
  ASSIGNMENT_STATUS_OPTS: any;
  DEMAND_TYPE_OPTS: any;
  DISTRICT_TYPE_OPTS: any;
  CAM_SETTABLE_STATUS: any = CAM_SETTABLE_STATUS;
  PL_REJECTED_REASONS_OPTS: any;
  PROVIDER_DECLINED_REASONS_OPTS: any;
  REMOVED_REASONS_OPTS: any;
  CEM_DEMO_REASON_OPTS: any;
  STATUS_TRANSITION_OPTS_MAP = {};
  PROBABILITIES_OPTS: Option[];
  PROBABILITIES_MAX_OPTS: Option[];
  FilterKey = FILTER_KEY;

  constructor(
    private util: PLUtilService,
    private plHttp: PLHttpService,
    private schoolYears: PLSchoolYearsService,
    private toastr: ToastrService,
    private states: PLApiUsStatesService,
    private assignmentProposalItemService: PLAssignmentProposalItemService,
  ) {
    this.ASSIGNMENT_STATUS_OPTS = Object.keys(ASSIGNMENT_STATUS)
      .map(key => ({ value: key, label: ASSIGNMENT_STATUS[key] }))
      .sort((a: Option, b: Option) => a.label.localeCompare(b.label));

    this.DEMAND_TYPE_OPTS = Object.keys(DEMAND_TYPES).map(key => ({
      value: key,
      label: DEMAND_TYPES[key],
    }));

    this.DISTRICT_TYPE_OPTS = Object.keys(DISTRICT_TYPES).map(key => ({
      value: key,
      label: DISTRICT_TYPES[key],
    }));

    this.PL_REJECTED_REASONS_OPTS = Object.keys(PL_REJECTED_REASONS)
      .map(key => ({ value: key, label: PL_REJECTED_REASONS[key] }))
      .sort((a: Option, b: Option) => a.label.localeCompare(b.label));

    this.PROVIDER_DECLINED_REASONS_OPTS = Object.keys(
      PROVIDER_REJECTED_REASONS,
    ).map(key => ({ value: key, label: PROVIDER_REJECTED_REASONS[key] }));

    this.REMOVED_REASONS_OPTS = Object.keys(REMOVED_REASONS)
      .map(key => ({ value: key, label: REMOVED_REASONS[key] }))
      .sort((a: Option, b: Option) => a.label.localeCompare(b.label));

    this.CEM_DEMO_REASON_OPTS = Object.keys(CEM_DEMO_REASONS).map(key => ({
      value: key,
      label: CEM_DEMO_REASONS[key],
    }));

    Object.keys(STATUS_TRANSITION_MAP).forEach((statusKey: string) => {
      const options = Object.keys(STATUS_TRANSITION_MAP[statusKey]).map(
        (item: any) => {
          return { value: item, label: ASSIGNMENT_STATUS[item] };
        },
      );
      this.STATUS_TRANSITION_OPTS_MAP[statusKey] = [
        { value: statusKey, label: ASSIGNMENT_STATUS[statusKey] },
        ...options,
      ];
    });
    this.PROBABILITIES_OPTS = PROBABILITIES_OPTS;
    this.PROBABILITIES_MAX_OPTS = PROBABILITIES_MAX_OPTS;
  }

  providers: any[];
  providerPool: any = {};
  providerPoolV2: any = {};

  fetchOrganizations(params?: any) {
    return this.util.fetchAll(
      'Organizations (GQL)',
      GQL_ORGANIZATIONS,
      params,
      (r: any) => r.organizations,
      (r: any) => r.organizations_totalCount,
    );
  }

  fetchStates() {
    return this.states.formOpts();
  }

  fetchProviders() {
    return this.util
      .fetchAll('Providers (REST)', 'providers', { user__is_active: true })
      .pipe(tap((r: any) => (this.providers = r)));
  }

  fetchProviderPoolV2(
    demandUuid: string,
    availableHours: number,
    dateForAvailability: string,
    lastWorkDate: string,
    includeAll: number = 0,
    includeProposed: number = 0,
    providerUuid: string = '',
  ) {
    if (
      !providerUuid &&
      this.providerPoolV2[demandUuid] &&
      this.providerPoolV2[demandUuid][availableHours] &&
      this.providerPoolV2[demandUuid][availableHours][dateForAvailability] &&
      this.providerPoolV2[demandUuid][availableHours][dateForAvailability][
        includeAll
      ] &&
      this.providerPoolV2[demandUuid][availableHours][dateForAvailability][
        includeAll
      ][includeProposed]
    ) {
      const providers =
        this.providerPoolV2[demandUuid][availableHours][dateForAvailability][
          includeAll
        ][includeProposed];
      return of({ results: providers });
    }

    let params = {
      demand: demandUuid,
      available_hours: availableHours,
      date_for_availability: dateForAvailability,
      include_all: includeAll,
      include_proposed: includeProposed,
      limit: 2000,
    };

    // if provider is given, we only want to fetch for that single provider
    if (providerUuid) {
      params['provider'] = providerUuid;
      params['include_proposed'] = 1;
      params['include_all'] = 0;
    }
    if (lastWorkDate) {
      params['last_work_date'] = lastWorkDate;
    }

    return this.plHttp.get('providerPoolV2', params).pipe(
      tap((r: any) => {
        if (providerUuid) return;

        if (!this.providerPoolV2[demandUuid]) {
          this.providerPoolV2[demandUuid] = {};
        }
        if (!this.providerPoolV2[demandUuid][availableHours]) {
          this.providerPoolV2[demandUuid][availableHours] = {};
        }
        if (
          !this.providerPoolV2[demandUuid][availableHours][dateForAvailability]
        ) {
          this.providerPoolV2[demandUuid][availableHours][dateForAvailability] =
            {};
        }
        if (
          !this.providerPoolV2[demandUuid][availableHours][dateForAvailability][
            includeAll
          ]
        ) {
          this.providerPoolV2[demandUuid][availableHours][dateForAvailability][
            includeAll
          ] = {};
        }
        this.providerPoolV2[demandUuid][availableHours][dateForAvailability][
          includeAll
        ][includeProposed] = r.results.sort(
          (a: any, b: any) => b.fitness - a.fitness,
        );
      }),
    );
  }

  fetchProviderPool(
    demandUuid: string,
    includePending: boolean,
    ignoreQualifications: boolean,
    ignoreAvailability: boolean,
  ) {
    const providers = this.providerPool[demandUuid];
    if (providers && providers.length) {
      return of(providers);
    }
    const params = { demand_uuid: demandUuid };
    if (includePending) {
      Object.assign(params, { include_pending: true });
    }

    if (ignoreQualifications) {
      Object.assign(params, { ignore_qualifications: true });
    }

    if (ignoreAvailability) {
      Object.assign(params, { ignore_availability: true });
    }

    return this.plHttp
      .get('providerPool', params)
      .pipe(tap((r: any) => (this.providerPool[demandUuid] = r)));
  }

  fetchServiceTypes() {
    return this.plHttp
      .get('serviceTypes', { limit: 1000 })
      .pipe(map((r: any) => r.results.filter(IS_NOT_FY23_PROVIDER)));
  }

  fetchSchoolYearsInfo() {
    return this.schoolYears.getSchoolYearsInfo();
  }

  fetchDemand(inParams: any) {
    return this.plHttp.get('demand', inParams);
  }

  fetchOrganizationServiceModel(
    inParams: any,
  ): Observable<OrganizationServiceModel> {
    return this.plHttp.get('organizationServiceModel', inParams);
  }

  fetchAssignmentsMarketplace(): Observable<AssignmentMarketplaceDemand[]> {
    return this.plHttp.get('assignmentsMarketplace').pipe(
      map((r: any) => {
        r.results.forEach((item: AssignmentMarketplaceDemand) => {
          item.unfulfilled_hours = this.durationToDecimalHours(
            item.unfulfilled_hours,
          );
        });
        return r.results;
      }),
    );
  }

  fetchAssignmentProposals(query: any = {}): Observable<AssignmentProposal[]> {
    return this.plHttp
      .get('assignmentProposals', { ...query, limit: 1000 })
      .pipe(
        map((r: any) => {
          // TODO:
          // - refactor this to return PLAssignmentProposalItem object(s)
          // - remove PLAssignmentInterface
          // - remove duped logic on translation from raw data and met/unmet calculation:
          //   - pl-provider-assignments
          //   - pl-assignment-manager

          // when querying for a specific proposal, plHttp.get() pulls out the results for us
          if (query.uuid) return r;

          return r.results;
        }),
      );
  }

  getSpecialtiesLabel(assignment: AssignmentProposal): string {
    return assignment.specialties
      .filter(s => ['AAC', 'ASL', 'DHH', 'VI', 'BI'].indexOf(s) > -1)
      .map(s => SPECIALTIES_LONG_NAME[s])
      .join(', ');
  }

  setProviderSupplyLists(
    obj: PLOpptyDemandItem,
    providerSupplyList: PLAssignmentProposalItem[],
    statuses: string,
  ) {
    obj.providerSupplyListOrig = providerSupplyList;
    obj.providerSupplyList = providerSupplyList.filter(
      (x: PLAssignmentProposalItem) =>
        !statuses ||
        this.assignmentProposalItemService.proposalMatchesFilter(x, statuses),
    );
  }

  buildProviderSupplyItem(
    opptyDemandUuid: string,
    proposalRaw: PLAssignmentProposalRaw,
    plTimezoneSvc: PLTimezoneService,
    timezone: string,
    pendingCompleteDate: any,
  ): PLAssignmentProposalItem {
    const separationDate = proposalRaw.user_separation_date
      ? dayjs(proposalRaw.user_separation_date)
      : null;

    const endDate = proposalRaw.end_date
      ? dayjs(proposalRaw.end_date, 'YYYY-MM-DD')
      : null;

    return {
      opptyDemandUuid,
      unmetRequirementsCount: proposalRaw.unmet_requirements_count,
      uuid: proposalRaw.uuid,
      provider: `${proposalRaw.user_first_name} ${proposalRaw.user_last_name}`,
      providerObj:
        this.providers &&
        this.providers.find((p: any) => p.user === proposalRaw.user),
      providerUuid: proposalRaw.user,
      supplyHours: this.durationToDecimalHours(proposalRaw.hours),
      directHours:
        proposalRaw.direct_hours &&
        this.durationToDecimalHours(proposalRaw.direct_hours),
      durationHours: proposalRaw.hours,
      statusCode: proposalRaw.status,
      statusLabel: ASSIGNMENT_STATUS[proposalRaw.status],
      statusLabelDetail: proposalRaw.status_detail,
      modified: plTimezoneSvc
        .toUserZone(proposalRaw.modified, null, timezone)
        .format('M/D/YY'),
      separationDate: separationDate ? separationDate.format('M/D/YY') : '',
      isSeparating: separationDate ? dayjs().diff(separationDate) < 0 : false,
      isSeparated: separationDate ? dayjs().diff(separationDate) >= 0 : false,
      isReturning: proposalRaw.user_returning_info.user_is_returning,
      providerPreferences: proposalRaw.user_returning_info.provider_preferences,
      organizationPreferences:
        proposalRaw.user_returning_info.organization_preferences,
      showEndDate: endDate
        ? proposalRaw.status === PLAssignmentStatusEnum.COMPLETED
        : false,
      showCompletingOnEndDate: endDate
        ? this.isCompleting(proposalRaw.status, endDate)
        : false,
      startDate: plTimezoneSvc
        .toUserZone(proposalRaw.start_date, 'YYYY-MM-DD', timezone)
        .format('M/D/YY'),
      endDate: plTimezoneSvc
        .toUserZone(proposalRaw.end_date, 'YYYY-MM-DD', timezone)
        .format('M/D/YY'),
      endDateRaw: endDate,
      demandPendingCompleteDateRaw: pendingCompleteDate,
      cemDemoReason: proposalRaw?.cem_demo_reason,
      blockAssignmentTimeReason:
        proposalRaw?.block_assignment_time_reason || null,
    };
  }

  isCompleting(status: string, endDate: any) {
    if (!endDate) return false;

    // completed, and proposal's end date after now
    return (
      status === PLAssignmentStatusEnum.COMPLETED && dayjs().diff(endDate) < 0
    );
  }

  buildDemandHours(orgDemand: any) {
    return orgDemand.demands.reduce(
      (hours: any, opptyDemand: PLOpptyDemandItemRaw) => {
        hours[opptyDemand.uuid] = Number(
          this.durationToDecimalHours(opptyDemand.hours),
        );
        return hours;
      },
      {},
    );
  }

  buildOrgDemandList(
    orgDemand: any,
    includeProposedInTotals: boolean,
    includeCapacityInTotals: boolean,
    statuses: string,
    plTimezoneSvc: PLTimezoneService,
    timezone: string,
  ): PLOrgDemandItem[] {
    /**
     * An orgDemandItem represents an order of demands for service by an organization
     *   uuid: "organization uuid"
     *   name: "organization name"
     *   demands: []
     */
    return orgDemand.map((orgDemandRaw: any) => {
      const opptyDemandList = this.buildOpptyDemandList(
        orgDemandRaw.demands,
        statuses,
        plTimezoneSvc,
        timezone,
      );
      const hoursByServiceDemand = this.buildDemandHours(orgDemandRaw);
      const obj: PLOrgDemandItem = {
        opptyDemandList,
        hoursByServiceDemand,
        orgName: orgDemandRaw.name,
        uuid: orgDemandRaw.uuid,
        hoursTotalDemand:
          this.assignmentProposalItemService.getTotalHours(
            hoursByServiceDemand,
          ),
        cam: orgDemandRaw.cam,
        csm: orgDemandRaw.csm,
        hoursByServiceSupply: 0, // set below
        hoursTotalSupply: 0, // set below
        fulfillmentPercentNormalized: 0, // set below
        state: orgDemandRaw.state,
        organizationType: orgDemandRaw.organization_type,
        csmSlpOt: orgDemandRaw.clinical_success_manager_slp_ot,
        csmPesMhc: orgDemandRaw.clinical_success_manager_pes_mhc,
        salesforceId: orgDemandRaw.salesforce_id,
      };

      this.assignmentProposalItemService.setOrgDemandItemSupplyTotal(
        obj,
        includeProposedInTotals,
        includeCapacityInTotals,
      );
      return obj;
    });
  }

  buildOpptyDemandList(
    demandsRaw: any,
    statuses: string,
    plTimezoneSvc: PLTimezoneService,
    timezone: string,
  ): PLOpptyDemandItem[] {
    /**
     * An opptyDemandItem represents an order-item of demand for an amount of service
     *   uuid: "demand uuid"
     *   opptyName: "oppty name"
     *   serviceGroupName: "service group name"
     *   hours: "16.0"
     *   totalHoursProposed: "3 23:00:00"
     *   proposals: []
     */
    return demandsRaw.map((opptyDemandItemRaw: PLOpptyDemandItemRaw) => {
      const pendingCompleteDate = dayjs(
        opptyDemandItemRaw.pending_complete_date,
      );

      const providerSupplyList = this.buildProposalList(
        opptyDemandItemRaw,
        plTimezoneSvc,
        timezone,
        pendingCompleteDate,
      );

      const obj: PLOpptyDemandItem = {
        opptyName:
          opptyDemandItemRaw.opportunity_name || '* missing opportunity name',
        uuid: opptyDemandItemRaw.uuid,
        hours: this.durationToDecimalHours(opptyDemandItemRaw.hours),
        unfulfilledHours: this.durationToDecimalHours(
          opptyDemandItemRaw.unfulfilled_hours,
        ),
        totalHoursProposed: this.durationToDecimalHours(
          opptyDemandItemRaw.total_hours_proposed,
        ),
        totalHoursCommitted: this.durationToDecimalHours(
          opptyDemandItemRaw.total_hours_committed,
        ),
        totalHoursCapacityPlanning: this.durationToDecimalHours(
          opptyDemandItemRaw.total_hours_capacity_planning,
        ),
        serviceGroupName: opptyDemandItemRaw.service_group,
        isESY: opptyDemandItemRaw.is_esy,
        probability: opptyDemandItemRaw.probability,
        pendingCompleteDate: pendingCompleteDate,
        longServiceTypeName: opptyDemandItemRaw.long_service_type_name,
        opportunityLineitemProductCode:
          opptyDemandItemRaw.opportunity_line_item_product_code,
        serviceModel: opptyDemandItemRaw.service_model,
        opportunityStage: opptyDemandItemRaw.opportunity_stage,
        contractDates: opptyDemandItemRaw.contract_dates,
        therapyStartDate: opptyDemandItemRaw.therapy_start_date,
        billingStartDate: opptyDemandItemRaw.billing_start_date,
        lastProviderBillingDate: opptyDemandItemRaw.last_provider_billing_date,
        opportunitySalesforceId: opptyDemandItemRaw.opportunity_salesforce_id,
        isAssessment: opptyDemandItemRaw.is_assessment,
        specialties: opptyDemandItemRaw.specialties,
      };

      this.setProviderSupplyLists(obj, providerSupplyList, statuses);
      return obj;
    });
  }

  buildProposalList(
    opptyDemandItemRaw: any,
    plTimezoneSvc: PLTimezoneService,
    timezone: string,
    pendingCompleteDate: any,
  ): PLAssignmentProposalItem[] {
    const proposalsRaw = opptyDemandItemRaw.proposals;
    const proposals = proposalsRaw.map((proposal: any) => {
      const opptyDemandUuid = opptyDemandItemRaw.uuid;
      const item: PLAssignmentProposalItem = this.buildProviderSupplyItem(
        opptyDemandUuid,
        proposal,
        plTimezoneSvc,
        timezone,
        pendingCompleteDate,
      );
      return item;
    });

    return proposals.sort(
      (a: PLAssignmentProposalItem, b: PLAssignmentProposalItem) => {
        const A = `${a.provider} ${a.statusCode}`.toLocaleLowerCase();
        const B = `${b.provider} ${b.statusCode}`.toLocaleLowerCase();
        return A.localeCompare(B);
      },
    );
  }

  buildServiceTypeInfo(types: any) {
    const ids = {};
    const codes = {};
    const opts: Option[] = [];
    types.forEach((item: any) => {
      ids[item.uuid] = item;
      opts.push({ label: item.short_name, value: item.uuid });
      codes[item.code] = item.short_name;
    });
    return { types, ids, opts, codes };
  }

  buildOrganizationOpts(orgs: any) {
    return orgs.map((org: any) => ({ label: org.name, value: org.id }));
  }

  buildProviderOpts(providers: any[]): Option[] {
    return providers.map((p: any) => ({
      label: `${p.first_name} ${p.last_name}`,
      value: p.user,
    }));
  }

  buildProviderOptsWithType(pool: any[]): Option[] {
    return pool.map((p: any) => {
      const label =
        `<h3><a href="${this.getProviderDashboardUrl(
          p.uuid,
        )}" target="_blank">${p.first_name} ${p.last_name}</a></h3>` +
        `${this.durationToDecimalHoursDecimal(
          p.remaining_hours,
        )} hours remaining<br />`;

      const color = p.rank === 4 ? 'red' : p.rank === 1 ? 'green' : '';

      return {
        label,
        value: p.uuid,
        uuid: p.uuid,
        name: `${p.first_name} ${p.last_name}`,
        remainingHours: this.durationToDecimalHoursDecimal(p.remaining_hours),
        hasPendingReqs: p.has_pending_reqs,
        isOnboarding: p.provider_sub_status === 'Onboarding',
        fitness: p.fitness,
        rankColor: color,
        rankDescription: p.rank
          ? `${p.rank_description} percentile (${p.fitness})`
          : '',
        removedReasons: p.removed_reasons.join(', '),
        dashboardUrl: this.getProviderDashboardUrl(p.uuid),
        separationDate: p.separation_date
          ? dayjs(p.separation_date).format('M/D/YYYY')
          : '',
        groups: p?.groups || [],
        hasPendingSpecialty: p.has_pending_specialty,
      };
    });
  }

  toastStatusResults(results: StatusUpdateResults) {
    if (results.saved.length) {
      const pluralized = results.saved.length === 1 ? 'item was' : 'items were';
      this.toastr.success(
        `${results.saved.length} ${pluralized} successfully updated`,
        '🎉 SUCCESS',
        {
          positionClass: 'toast-bottom-right',
          timeOut: TOAST_TIMEOUT,
          enableHtml: true,
        },
      );
    }
    if (results.ignored.length) {
      const pluralized = results.ignored.length === 1 ? 'item' : 'items';
      this.toastr.info(
        `${results.ignored.length} ${pluralized} could not be changed`,
        '🔒 IGNORED',
        {
          positionClass: 'toast-bottom-right',
          timeOut: TOAST_TIMEOUT,
          enableHtml: true,
        },
      );
    }
    if (results.failed.length) {
      const pluralized = results.ignored.length === 1 ? 'item' : 'items';
      this.toastr.error(
        `${results.failed.length} ${pluralized} failed with errors`,
        '❌ FAILED',
        {
          positionClass: 'toast-bottom-right',
          timeOut: TOAST_TIMEOUT,
        },
      );
    }
  }

  // `1 05:19:12` (1 day, 5 hours, 19 minutes, 12 seconds) to decimal hours 29.32
  durationToDecimalHoursDecimal(duration: string): number {
    const hours = dayjs.duration(this.parseDurationString(duration)).asHours();
    return this.roundTwoDecimalPlaces(hours);
  }

  parseDurationString(duration: string) {
    // Initialize days to 0
    let days = '0';
    let time = duration;

    // Check if the duration string contains a space
    if (duration.includes(' ')) {
      // Split the duration string into days and time parts
      [days, time] = duration.split(' ');
    }

    // Split the time part into hours, minutes, and seconds
    const [hours, minutes, seconds] = time.split(':');

    // Return the duration in ISO 8601 format
    return {
      days: parseInt(days),
      hours: parseInt(hours),
      minutes: parseInt(minutes),
      seconds: parseInt(seconds),
    };
  }

  // duration format to decimal hours
  durationToDecimalHours(duration: string): string {
    const HOURS = this.durationToDecimalHoursDecimal(duration);
    return `${HOURS}`;
  }

  // decimal hours to duration format
  decimalHoursToDuration(decimalHours: string) {
    const d = dayjs.duration(Number(decimalHours), 'h');
    const _days = d.get('days');
    const DAYS = (_days && `${_days} `) || '';
    const HOURS = `${d.get('hours')}`.padStart(2, '0');
    const MINUTES = `${d.get('minutes')}`.padStart(2, '0');
    const SECONDS = `${d.get('seconds')}`.padStart(2, '0');
    return `${DAYS}${HOURS}:${MINUTES}:${SECONDS}`;
  }

  roundTwoDecimalPlaces(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  getCharsLength(text: string) {
    return text && text.length;
  }

  isRequirementMet(requirement: PLAssignmentRequirement) {
    return (
      requirement.met &&
      requirement.qualification &&
      requirement.qualification.length
    );
  }

  getProviderDashboardUrl(providerUuid: any) {
    return `https://metabase.presencelearning.com/dashboard/110?uuid=${this.stripHyphens(
      providerUuid,
    )}`;
  }

  getAccountDashboardUrl(accountName: any) {
    return `https://metabase.presencelearning.com/dashboard/119?account=${encodeURIComponent(
      accountName,
    )}`;
  }

  getOrganizationServiceModel(
    schoolYearId: string,
    organizationId: string,
  ): any {
    return this.fetchOrganizationServiceModel({
      school_year: schoolYearId,
      organization: organizationId,
    });
  }

  private stripHyphens(s: string) {
    return s.replace(/-/g, '');
  }
}
