import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PLModalService } from '@root/src/lib-components';
import { Router } from '@angular/router';

@Component({
  selector: 'pl-assignment-error-modal',
  templateUrl: './pl-assignment-error-modal.component.html',
  styleUrls: ['./pl-assignment-error-modal.component.less'],
})
export class PlAssignmentErrorModalComponent {
  @Output() refreshWindow = new EventEmitter<boolean>();

  constructor(private plModalSvc: PLModalService, private router: Router) {}

  onOk() {
    this.closeModal();
  }

  onRefresh() {
    
    this.refreshWindow.emit(true);
   this.closeModal();
  }

  closeModal() {
    this.plModalSvc.destroyAll();
  }
}
