import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pl-quick-links-card',
  templateUrl: './pl-quick-links-card.component.html',
  styleUrls: ['./pl-quick-links-card.component.less'],
})
export class PlQuickLinksCardComponent {
  @Input() organizations: { name: string; id: string }[];
  @Input() showOrgHandbooks: boolean;
}
