import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { orderBy } from 'lodash';
import { forkJoin } from 'rxjs';
import { defaultIfEmpty, first } from 'rxjs/operators';
import { LEARN_UPON_DASHBOARD_URL } from '@common/constants';
import { PLProviderTypeCode } from '@root/src/app/common/enums';
import {
  PLProviderTypesService,
  PLTasksService,
} from '@root/src/app/common/services';
import { PLLearnUponLauncherService } from '@root/src/app/modules/launcher/pl-learnupon-launcher/pl-learnupon-launcher.service';
import { environment } from '@root/src/environments/environment';
import {
  PLConfirmDialogService,
  PLGraphQLService,
} from '@root/src/lib-components';
import * as PLOnboardingTasks from './pl-onboarding-tasks';
import { ARCLabel, ARCLink } from './pl-onboarding-tasks';
import { User } from '../../../user/user.model';

const updateTaskOwnerCompleteQuery = require('../queries/update-task-owner-complete.graphql');
const updateTaskOwnerQuery = require('../queries/update-task-owner.graphql');

@UntilDestroy()
@Component({
  selector: 'pl-onboarding-tasks',
  templateUrl: './pl-onboarding-tasks.component.html',
  styleUrls: ['./pl-onboarding-tasks.component.less'],
})
export class PLOnboardingTasksComponent implements OnInit, OnDestroy {
  @Input() currentUser: User;

  ONBOARDING_TASK = PLOnboardingTasks.OnboardingTask;

  alertsToShow = { task: '' };

  alertTask: any = null;

  arcTasks: { href: string; label: string }[]; // Assignment Readiness Check (ARC)

  fullName = '';

  showTips = {
    onboarding: false,
    tasks: false,
    availability: false,
    schedule: false,
    room: false,
  };

  loadingTasks = true;

  newCount = 0;

  salesforceId = '';

  tasks: any[] = [];

  tasksOnboarding: any[] = [];

  tasksV2Onboarding: any[] = [];

  tasksOnboardingAllComplete = false;

  v2OnboardingTasks = false;

  tasksOnboardingCheckboxVals = {};

  learnUponDashboardUrl: string;

  isCurrentUserPsychoEducationalAssessor: boolean = false;

  driversLicenseLink: string =
    'https://support.presence.com/hc/en-us/articles/27203430489363-Driver-s-License-Requirements';

  gettingStartedLink: string =
    'https://support.presence.com/hc/en-us/categories/27025828503955-Getting-Started-with-Presence-Start-Here';

  liabilityInsuranceLink: string =
    'https://support.presence.com/hc/en-us/articles/27203678177811-Liability-Insurance-Requirements';

  loungeLink: string =
    'https://support.presence.com/hc/en-us/articles/27203624955027-The-Lounge-Social-Networking-Account-Management';

  npiLink: string =
    'https://support.presence.com/hc/en-us/articles/27201548338835-National-Provider-Identifier-NPI-Requirements';

  organizationHandbookLink: string =
    'https://support.presence.com/hc/en-us/articles/27203500824595-Organization-Org-Handbooks';

  taxLink: string =
    'https://support.presence.com/hc/en-us/articles/27203434353299-Tax-Information-Center';

  telehealthLink: string =
    'https://support.presence.com/hc/en-us/articles/27201531724179-Telehealth-Institute';

  private isW2BillingType = false;

  private providerType: string;

  private tasksSubscription: any;

  constructor(
    private router: Router,
    private plGraphQL: PLGraphQLService,
    private plTasksService: PLTasksService,
    private plConfirm: PLConfirmDialogService,
    private plProviderTypesSvc: PLProviderTypesService,
    private plLearnUponLauncherService: PLLearnUponLauncherService,
  ) {
    this.learnUponDashboardUrl = LEARN_UPON_DASHBOARD_URL;
  }

  ngOnInit(): void {
    this.setProviderTypeBasedOnUser();
    this.fullName = `${this.currentUser.first_name} ${this.currentUser.last_name}`;

    if (this.currentUser.uuid) {
      if (this.currentUser.xProvider?.salesforce_id) {
        this.salesforceId = this.currentUser.xProvider.salesforce_id;
      }

      if (
        !this.currentUser.xProvider?.is_onboarding_wizard_complete &&
        this.currentUser.xProvider?.providerSubStatus === 'Onboarding'
      ) {
        this.router.navigate(['provider-onboarding']);
        this.alertsToShow.task = 'no';
      } else {
        this.alertsToShow.task = 'yes';
      }

      this.checkShowAlerts();
    }

    if (this.currentUser.groups && this.providerType) {
      this.isCurrentUserPsychoEducationalAssessor =
        this.providerType === PLProviderTypeCode.PA;
      this.isW2BillingType = this.currentUser.xProvider?.isW2;
      this.setAssignmentReadinessCheckTasks();
      this.setTasks();
    }
  }

  ngOnDestroy(): void {
    this.tasksSubscription?.unsubscribe();
  }

  checkAllOnboardingTasksComplete(): void {
    let allComplete = true;
    for (let ii = 0; ii < this.tasksOnboarding.length; ii++) {
      if (!this.tasksOnboarding[ii].xIsComplete) {
        allComplete = false;
        break;
      }
    }
    this.tasksOnboardingAllComplete = allComplete;
  }

  checkShowAlerts(): void {
    if (this.alertsToShow.task !== '') {
      if (this.alertsToShow.task === 'yes' && this.alertTask !== null) {
        this.showPriorityAlert(this.alertTask);
      }
    }
  }

  /**
   * Three scenarios are supported here:
   *   1. User could have onboarding tasks.
   *   2. User could have regular tasks (not onboarding ones)
   *   3. User could not have any of the above.
   *     This'd mean that the user doesn't have any task pending or is an unsupported user in onboarding.
   *
   * @param tasks The tasks gotten from the GQL 'tasks' query.
   * @returns An object holding the tasks that the user has pending; `normal` and `pending`.
   */
  filterTasksIntoGroupsAndCheckPriority(tasks: any[]): {
    normal: [];
    onboarding: [];
    v2onboarding: [];
  } {
    let lowestPriorityValue = -1;
    let priorityTask = {};
    const groups: any = {
      normal: [],
      onboarding: [],
      v2onboarding: [],
    };

    const plOnboardingTasks = this.isW2BillingType
      ? PLOnboardingTasks.TASKS_FOR_W2_PROVIDER
      : PLOnboardingTasks.TASKS_FOR_1099_PROVIDER;
    const providerTypeOnboardingTasks = plOnboardingTasks[this.providerType]; // This tells us if it's a supported type of user.

    if (tasks?.length) {
      tasks.forEach((task: any) => {
        if (
          task.taskType.code.includes('onboarding-') &&
          providerTypeOnboardingTasks.includes(task.taskType.code)
        ) {
          // Get owner for this user id since isComplete is in there.
          let isComplete = false;
          for (let ii = 0; ii < task.owners.length; ii++) {
            if (task.owners[ii].user.id === this.currentUser.uuid) {
              isComplete = task.owners[ii].isComplete;
              break;
            }
          }
          groups.onboarding.push(
            Object.assign(task, {
              xSort:
                PLOnboardingTasks.OnboardingOrderMap[task.taskType.code] || 100,
              xIsComplete: isComplete,
              xHidden: isComplete,
            }),
          );
        } else if (task.taskType.code.includes('v2onboarding')) {
          let isComplete = false;
          for (let ii = 0; ii < task.owners.length; ii++) {
            if (task.owners[ii].user.id === this.currentUser.uuid) {
              isComplete = task.owners[ii].isComplete;
              break;
            }
          }
          groups.v2onboarding.push(
            Object.assign(task, {
              isComplete,
            }),
          );
        } else {
          groups.normal.push(task);
        }
        if (lowestPriorityValue === -1 || task.priority < lowestPriorityValue) {
          lowestPriorityValue = task.priority;
          priorityTask = task;
        }
      });
    }

    if (lowestPriorityValue >= 0 && lowestPriorityValue < 3) {
      this.alertTask = priorityTask;
      // Do not want to show alert if redirecting.
      if (this.currentUser.uuid) {
        this.checkShowAlerts();
      }
    }

    if (groups.v2onboarding.length > 0) {
      const incompleteTasks = groups.v2onboarding.filter(task => {
        return !task.isComplete;
      });

      if (incompleteTasks.length > 0) {
        this.v2OnboardingTasks = true;
      }
    }

    return groups;
  }

  handleTask(t: any): void {
    const mutations = t.owners
      .filter((o: any) => o.user.id === this.currentUser.uuid && !o.read)
      .map((o: any) =>
        this.plGraphQL.mutate(
          updateTaskOwnerQuery,
          { id: o.id, read: true },
          {},
        ),
      );

    forkJoin(mutations)
      .pipe(defaultIfEmpty([]))
      .subscribe(res => {
        if (t.taskType.code === 'unsigned_w2_preagreement') {
          window.open(t.actionUrl, '_blank');
        } else {
          window.location.href = t.actionUrl;
        }
      });
  }

  showPriorityAlert(task: any): void {
    const secondaryLabel = task.priority === 2 ? 'Later' : null;
    this.plConfirm.show({
      secondaryLabel,
      header: 'Important Task To Complete',
      content: `<div>${task.message}</div>`,
      primaryLabel: 'Continue',
      primaryCallback: () => {
        window.location.href = task.actionUrl;
      },
      secondaryCallback: () => {
        if (task.priority < 2) {
          window.location.href = task.actionUrl;
        }
      },
      closeCallback: () => {
        if (task.priority < 2) {
          window.location.href = task.actionUrl;
        }
      },
    });
  }

  setTasks(): void {
    this.tasksSubscription = this.plTasksService
      .getTasks()
      .pipe(first())
      .subscribe((res: any) => {
        const taskGroups = this.filterTasksIntoGroupsAndCheckPriority(
          res.tasks,
        );
        if (taskGroups.v2onboarding.length > 0) {
          this.tasksV2Onboarding = taskGroups.v2onboarding;
        } else {
          this.tasksOnboarding = orderBy(
            taskGroups.onboarding,
            ['xSort'],
            ['asc'],
          );
          this.checkAllOnboardingTasksComplete();
        }

        const list = orderBy(
          taskGroups.normal,
          ['severity', 'age', 'message'],
          ['asc', 'desc', 'asc'],
        );
        this.tasks = list;
        this.newCount = 0;
        list.forEach((t: any) => {
          t.owners.forEach((o: any) => {
            if (o.user.id === this.currentUser.uuid) {
              if (!o.read) {
                t.isRead = false;
                this.newCount++;
              } else {
                t.isRead = true;
              }
            }
          });
        });
        this.loadingTasks = false;
      });

    this.plTasksService.refresh();
  }

  tasksOnboardingToggleHidden(task: any): void {
    task.xHidden = !task.xHidden;
  }

  tasksOnboardingCompleteTask(task: any): void {
    let id = '';
    task.owners.forEach((owner: any) => {
      if (owner.user.id === this.currentUser.uuid) {
        id = owner.id;
      }
    });
    // read is apparently a required field so just set it to false.
    this.plGraphQL
      .mutate(
        updateTaskOwnerCompleteQuery,
        { id, isComplete: true, read: false },
        {},
      )
      .subscribe(() => {
        this.plTasksService.refresh();
      });
    task.xIsComplete = true;
    task.xHidden = true;
    this.checkAllOnboardingTasksComplete();
  }

  toggleShowTips(key: string): void {
    this.showTips[key] = !this.showTips[key];
  }

  // Course codes can be found in https://presencelearning.atlassian.net/browse/PRO-247
  setPracticeARCLink(arcTasks: { href: string; label: string }[]) {
    const COURSE_PA = environment.production ? '3481' : '3567';
    const COURSE_EDDIAG = environment.production ? '3469' : '3569';
    const COURSE_OT_SLP_MHP = environment.production ? '3467' : '3571';

    let url: string = '';
    switch (this.providerType) {
      case PLProviderTypeCode.PA: // School Psychologist
        url = this.plLearnUponLauncherService.getCourseUrl(COURSE_PA, true);
        break;
      case PLProviderTypeCode.EDDIAG:
        url = this.plLearnUponLauncherService.getCourseUrl(COURSE_EDDIAG, true);
        break;
      case PLProviderTypeCode.OT:
      case PLProviderTypeCode.SLP:
      case PLProviderTypeCode.MHP:
        url = this.plLearnUponLauncherService.getCourseUrl(
          COURSE_OT_SLP_MHP,
          true,
        );
        break;
      default:
        url = this.plLearnUponLauncherService.getDashboardUrl(true);
        break;
    }

    arcTasks.forEach((task: { href: string; label: string }) => {
      if (task.href === PLOnboardingTasks.ARCLink.practiceARC) {
        task.href = url;
      }
    });
    return arcTasks;
  }

  /**
   * The (ARC) tasks are specific for the 'onboarding-assignment-readiness-check' task/step.
   * Based on the provider type the ARC tasks are set.
   */
  private setAssignmentReadinessCheckTasks(): void {
    let sharedTasks: { href: string; label: string }[] = [
      {
        href: ARCLink.preparationARC,
        label: ARCLabel.preparationARC,
      },
      {
        href: ARCLink.completionARC,
        label: ARCLabel.completionARC,
      },
    ];

    if (this.isCurrentUserPsychoEducationalAssessor && this.salesforceId) {
      this.arcTasks = [
        {
          href: `${ARCLink.submissionARC}?tfa_5=${this.salesforceId}`,
          label: ARCLabel.submissionARC,
        },
        ...sharedTasks,
      ];
    } else {
      this.arcTasks = sharedTasks;
    }
  }

  /**
   * Based on the current user; its provider type is set
   */
  private setProviderTypeBasedOnUser(): void {
    let providerType: any = this.plProviderTypesSvc.getProviderTypeFromUser(
      this.currentUser,
    );

    if (providerType?.length > 0) {
      if (
        // PA's like School Psychologists - also known as PES (Psycho-educational Services) - may have both BMH (Behavioral and Mental Health) and PA (Psycho-Educational Assessors) group assignment
        providerType?.length === 2 &&
        providerType.includes(PLProviderTypeCode.PA) &&
        providerType.includes(PLProviderTypeCode.MHP)
      ) {
        providerType = PLProviderTypeCode.PA;
      } else {
        providerType = providerType[0];
      }
    }

    this.providerType = providerType;
  }
}
