<div class="container" mat-dialog-content>
  <div class="title">
    <h3>{{ modalInput.title }}</h3>
    <button
      #closeButton
      mat-icon-button
      (click)="onNoClick()"
      class="close-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="divider"></div>
  <p *ngIf="modalInput.action === 'onHold'">
    You are about to put
    <strong>{{ holdReferrals }}</strong> referrals on hold.
  </p>
  <p *ngIf="modalInput.action === 'releaseHold'">
    You are about to remove the on-hold status for
    <strong>{{ holdReferrals }}</strong> referrals.
  </p>
  <form (ngSubmit)="submitBulkAction()">
    <mat-form-field
      *ngIf="modalInput.action === 'onHold'"
      class="reason"
      appearance="outline"
      placeholder="Select a referral"
      cdkFocusInitial
    >
      <mat-label>Reason for hold</mat-label>
      <mat-select [formControl]="referralControl" required>
        <mat-option *ngFor="let reason of reasons" [value]="reason.value">
          {{ reason.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="referralControl.invalid">Reason is required</mat-error>
    </mat-form-field>
    <div *ngIf="noHoldReferrals.length" class="disclaimer">
      <p>
        <strong
          >{{ noHoldReferrals.length }} referrals will not be put on
          hold</strong
        >
        because they have already been converted, deleted, on hold or are RSM
        referrals.
      </p>
    </div>
    <div class="button-container">
      <button type="button" mat-button color="primary" (click)="onNoClick()">
        Cancel
      </button>
      <button
        type="submit"
        mat-button
        color="primary"
        [disabled]="
          modalInput.action === 'onHold' &&
          (referralControl.invalid || holdReferrals === 0)
        "
        class="submit-button"
      >
        {{ modalInput.action === 'onHold' ? 'Confirm hold' : 'Release hold' }}
      </button>
    </div>
  </form>
</div>
