<div class="modal-content">
  <div class="flex flex-col">
    <div class="flex w-full">
      <h2>Assignment accepted!</h2>
    </div>
    <div class="details">
      <h3 class="organization">{{ demandOrganization }}</h3>
      <div class="service-line">{{ demandServiceLine }}</div>

      <div class="flex justify-center">
        <img src="assets/frame.png" alt="Description of image" />
      </div>
      <div class="w-full content">
        Congrats! Look out for an email from your Clinical Success Manager with
        more details and introduction to the district. The assignment can now be found under the “My assignments” tab.
      </div>
    </div>
    <div class="flex w-full gap-24 justify-end">
      <button
        mat-flat-button
        color="primary"
        class="save-basic-info-button"
        type="submit"
        (click)="onOk()"
      >
      Continue
      </button>
    </div>
  </div>
</div>
