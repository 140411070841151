import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

interface Link {
  href: string;
  target?: string;
  icon?: string;
  label: string;
  children?: Link[];
}

@Component({
  selector: 'pl-side-nav-link',
  templateUrl: './pl-side-nav-link.component.html',
  styleUrls: ['./pl-side-nav-link.component.less'],
})
export class PLSideNavLinkComponent implements OnInit {
  @Input() link: Link;
  @Input() navCollapsed: boolean;

  isActive: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.checkActiveLink(this.router.url);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.checkActiveLink((event as NavigationEnd).urlAfterRedirects);
      });
  }

  checkActiveLink(url: string) {
    const urlWithoutQueryParams = url.split('?')[0];

    const paths = [
      this.link.href,
      ...(this.link.children?.map(child => child.href) ?? []),
    ];

    this.isActive = paths.some(path => urlWithoutQueryParams.endsWith(path));
  }
}
