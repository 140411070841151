import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStore } from '@app/appstore.model';
import { User } from '@modules/user/user.model';
import { PLUrlsService, PLHttpService } from '@root/index';
import { PLLearnUponLauncherService } from '@root/src/app/modules/launcher/pl-learnupon-launcher/pl-learnupon-launcher.service';
import { environment } from '@root/src/environments/environment';

@Component({
  selector: 'pl-resources',
  templateUrl: './pl-resources.component.html',
  styleUrls: ['./pl-resources.component.less'],
})
export class PLResourcesComponent implements OnInit, AfterViewInit {
  @ViewChild('helpAndSupportMenu') helpAndSupportMenu: TemplateRef<any>;
  @ViewChild('trigger') triggerElement: ElementRef;
  @Input() userType: string;
  @Input() navCollapsed: boolean;

  helpAndSupportMenuPortal: TemplatePortal<any>;
  overlayRef: OverlayRef;
  links: { icon: string; label: string; href: string; target: string }[] = [];
  helpAndSupportLink = {
    icon: 'help',
    label: 'Help & Support',
  };
  isMenuOpen = false;
  learnUponUrl = '';
  techCheckUrl = '';
  version = '';
  currentUser: User;

  constructor(
    private plLearnUponService: PLLearnUponLauncherService,
    private plHttp: PLHttpService,
    private plUrls: PLUrlsService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private store: Store<AppStore>,
  ) {}

  ngOnInit() {
    this.store.select('currentUser').subscribe((user: any) => {
      this.currentUser = user;
    });
    this.links =
      this.userType === 'CUSTOMER'
        ? []
        : [
            {
              icon: 'mail',
              label: 'Presence Mail',
              href: 'http://mail.presencelearning.com/',
              target: '_blank',
            },
            {
              icon: 'groups',
              label: 'The Lounge',
              href: 'https://presencelearning.com/lounge',
              target: '_blank',
            },
          ];
    this.learnUponUrl = this.plLearnUponService.getDashboardUrl();
    this.techCheckUrl = this.plUrls.urls.techcheckFE;
    this.version = environment.git_sha.slice(0, 4);
  }

  ngAfterViewInit() {
    this.helpAndSupportMenuPortal = new TemplatePortal(
      this.helpAndSupportMenu,
      this.viewContainerRef,
    );
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.triggerElement)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]);

      const overlayConfig = new OverlayConfig({
        positionStrategy: positionStrategy,
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block(),
      });

      this.overlayRef = this.overlay.create(overlayConfig);
      this.overlayRef.attach(this.helpAndSupportMenuPortal);
      this.overlayRef.backdropClick().subscribe(() => this.toggleMenu());
    } else {
      this.overlayRef.detach();
    }
  }

  onClickSupportChat() {
    const zenDeskwidget = window['zE'];
    this.plHttp
      .get('', {}, this.plUrls.urls.gatewayZendeskTokenCreation)
      .subscribe((resp: any) => {
        if (resp.exists_in_zendesk) {
          zenDeskwidget('messenger', 'loginUser', function (callback) {
            callback(resp.jwt);
          });
          zenDeskwidget('messenger:set', 'conversationFields', [
            { id: '30009313905171', value: this.currentUser.email },
            {
              id: '26287517467923',
              value: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            },
          ]);
        }
        zenDeskwidget('messenger', 'show');
        zenDeskwidget('messenger', 'open');
      });
  }
}
