<div class="pl-decline-assignments-modal">
  <div class="heading">
    <span>Decline proposal</span>
    <button mat-icon-button (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="decline-assignments-blurb">
    <div class="org">{{ providerAssignment?.orgName }}</div>
    <div class="service-line">
      {{ providerAssignment?.main_service_line_title }}
    </div>
    <div class="note">
      Let your Presence team know why this assignment isn't a good fit for you.
    </div>
  </div>
  <div>
    <form [formGroup]="declineAssignmentsFormGroup">
      <div>
        <div>
          <mat-form-field class="reason" appearance="outline">
            <mat-label>Reason</mat-label>
            <mat-select formControlName="reason" [required]="true">
              <mat-option disabled>Select a reason</mat-option>
              <mat-option
                *ngFor="let option of declinedReasonsOpts"
                [value]="option.value"
              >
                {{ option.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="declineAssignmentsFormGroup.get('reason').invalid"
              >Reason is required</mat-error
            >
          </mat-form-field>
        </div>
        <div [hidden]="!isReasonOther()">
          <div>
            <mat-form-field class="other-reason" appearance="outline">
              <mat-label>Write in your own reason</mat-label>
              <textarea
                matInput
                formControlName="notes"
                [required]="true"
                [maxlength]="350"
                rows="4"
              ></textarea>
              <mat-error
                *ngIf="declineAssignmentsFormGroup.get('notes').invalid"
                >Notes are required</mat-error
              >
            </mat-form-field>
          </div>

          <div class="countdown">{{ getNotesChars() }}/350</div>
        </div>
      </div>

      <div class="form-actions">
        <button
          mat-stroked-button
          type="button"
          color="primary"
          (click)="onClickCancel()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="primary"
          type="button"
          [disabled]="inFlight || declineAssignmentsFormGroup.invalid"
          (click)="onClickDecline()"
        >
          Decline
        </button>
      </div>
    </form>
  </div>
</div>
