export enum PL_CLIENT_SERVICE_STATUS {
  NOT_STARTED = 'not_started',
  IN_PROCESS = 'in_process',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  ON_HOLD = 'on_hold',
}

export enum PL_CLIENT_SERVICE_ON_HOLD_REASON {
  PROVIDER_BACKFILL_NEEDED = 'provider_backfill_needed',
  PENDING_STUDENT_AVAILABILITY = 'pending_student_availability',
  NO_PARENT_CONSENT = 'no_parent_consent',
  EXTEND_DUE_DATE = 'extend_due_date',
}
export enum PL_CLIENT_SERVICE_CANCELLED_REASON {
  STUDENT_REMOVED_BY_SCHOOL = 'STUDENT_REMOVED_BY_SCHOOL',
  STUDENT_MOVED_OUT = 'STUDENT_MOVED_OUT',
  OTHER = 'OTHER',
}

export enum PL_CLIENT_SERVICE_COMPLETED_REASON {
  STUDENT_EXITED = 'STUDENT_EXITED',
  END_OF_YEAR = 'END_OF_YEAR',
  OTHER = 'OTHER',
}

export enum PL_CLIENT_SERVICE_TYPE {
  DIRECT_SERVICE = 'DirectService',
  EVALUATION = 'Evaluation',
}
