import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PLModalService } from '@root/src/lib-components';

@Component({
  selector: 'pl-assignment-accepted-modal',
  templateUrl: './pl-assignment-accepted-modal.component.html',
  styleUrls: ['./pl-assignment-accepted-modal.component.less'],
})
export class PlAssignmentAcceptedModalComponent {
  @Input() demandOrganization: string;
  @Input() demandServiceLine: string;

  @Output() changeTab = new EventEmitter<void>();

  constructor(private plModalSvc: PLModalService) {}

  onOk() {
    this.closeModal();
  }

  onViewAssignment() {
    this.changeTab.emit();
    this.closeModal();
  }

  closeModal() {
    this.plModalSvc.destroyAll();
  }
}
