<ng-container *ngIf="showNewLandingPage">
  <ng-container *ngIf="tasksV2Onboarding.length > 0">
    <pl-provider-onboarding-v2
      [currentUser]="currentUser"
      [tasks]="tasksV2Onboarding"
    ></pl-provider-onboarding-v2>
  </ng-container>
  <pl-landing-page></pl-landing-page>
</ng-container>
<ng-container *ngIf="!showNewLandingPage">
  <div class="pl-dashboard" *ngIf="pageInitialized">
    <div class="grid-container pl-dashboard-columns">
      <div class="pl-dashboard-left-column">
        <pl-onboarding-tasks
          *ngIf="currentUser.uuid || currentUser.groups"
          [currentUser]="currentUser"
        >
        </pl-onboarding-tasks>
        <div class="contact grid-item">
          <span class="section-header">
            <span>Accounts & Services</span>
          </span>
          <div class="card">
            <div class="margin-large-b links-section">
              <div class="margin-b links-header">Help & Support</div>
              <div class="margin-large-b">
                <div class="font-h4">Support Services</div>
                <div>
                  <a href="mailto:asksupport@presence.com"
                    >asksupport@presence.com</a
                  >
                </div>
                <div>1-844-415-4592</div>
                <br />
                <div class="font-h4">Equipment</div>
                <div>
                  <a
                    href="https://support.presence.com/hc/en-us/articles/33307170975379-Home-Office-Setup-Guide"
                    target="_blank"
                    >Home Office Setup Guide</a
                  >
                </div>
              </div>
            </div>
            <div class="marging-large-b links-section">
              <div>
                <div class="links-header">External Resources</div>
                <div>
                  <a
                    target="_blank"
                    href="https://qglobal.pearsonassessments.com/qg/login.seam"
                    >Q-Global</a
                  >
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://www.wjscore.com/WJIV/Home/Index/2"
                    >WJ Score</a
                  >
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://platform.wpspublish.com/account/login"
                    >Western Psychological Services</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pl-dashboard-right-column">
        <div class="room grid-item">
          <span class="section-header">
            <span>Presence Resources</span>
          </span>
          <div class="card">
            <div class="section">
              <div
                class="app-item inline-block padding-lr"
                *ngFor="let link of apps"
              >
                <a
                  *ngIf="link.href"
                  routerLink="{{ link.href }}"
                  routerLinkActive="active"
                  [target]="link.isNewWindow ? '_blank' : null"
                >
                  <pl-icon
                    class="blue-medium"
                    [svg]="link.icon"
                    [scale]="1.5"
                  ></pl-icon>
                  <span class="font-h5 blue-medium" [ngClass]="link.cssClass">
                    <b> {{ link.label }} </b>
                  </span>
                </a>

                <a
                  *ngIf="link.hrefAbsolute"
                  class="link-unstyled link-no-color"
                  target="_blank"
                  href="{{ link.hrefAbsolute }}"
                >
                  <pl-icon
                    class="blue-medium"
                    [svg]="link.icon"
                    [scale]="1.5"
                  ></pl-icon>
                  <span class="font-h5 blue-medium" [ngClass]="link.cssClass">
                    <b> {{ link.label }} </b>
                  </span>
                </a>
              </div>
            </div>
            <div class="section" *ngIf="showCEMInfo">
              <div class="padding-lr">
                <span
                  id="provider-cem-header"
                  class="font-h4 provider-cem-header"
                  >Empowerment Manager</span
                >
                <pl-icon
                  class="blue-medium pointer"
                  [svg]="'info'"
                  [height]="18"
                  [width]="18"
                  [verticalAlign]="'-1px'"
                  (click)="toggleShowTips('cem')"
                ></pl-icon>
                <div [hidden]="!showTips.cem" class="tip">
                  Your Empowerment Manager is your ongoing point of contact to
                  ensure a positive experience at Presence. Contact them for
                  general clinical needs.
                </div>
                <div class="margin-large-t margin-small-b provider-cem-info">
                  {{ accountOwner?.firstName }} {{ accountOwner?.lastName }} -
                  <a href="mailto://{{ accountOwner.email }}">
                    {{ accountOwner.email }}
                  </a>
                </div>
              </div>
            </div>
            <div class="room-title margin-small-b">
              <span class="font-h4">Room URL</span>
              <pl-icon
                class="blue-medium pointer"
                [svg]="'info'"
                [height]="18"
                [width]="18"
                [verticalAlign]="'-1px'"
                (click)="toggleShowTips('room')"
              ></pl-icon>
            </div>

            <div [hidden]="!showTips.room" class="tip">
              Here is your Room URL which you can share with students. This will
              allow them to enter your Room for therapy sessions.
              <a target="_blank" [href]="learnMoreAboutYourRoomLink"
                >Learn More About Your Room</a
              >
            </div>
            <div class="copy margin-small-b" [ngClass]="{ copied: isCopied() }">
              <input id="room-url" class="url" value="{{ roomUrl }}" readonly />
              <span class="copy-text" (click)="copyRoomUrl()">
                <pl-icon
                  *ngIf="isCopied()"
                  class="white"
                  [svg]="'check'"
                  [scale]="1"
                ></pl-icon>
                <span class="copy-label" *ngIf="!isCopied()">Copy</span>
              </span>
            </div>
            <div class="margin-large-t margin-small-b">
              Experiencing issues with your Room?
            </div>
            <div>
              <button pl-button class="link" (click)="onClickSupportChat()">
                Chat with Support
              </button>
            </div>
          </div>
        </div>

        <div
          class="schedule grid-item"
          *ngIf="
            !currentUser.xProvider ||
            currentUser.xProvider.providerSubStatus !== 'Onboarding'
          "
        >
          <span class="section-header">
            <div class="flexbox">
              <div class="flex1">
                <span>My Schedule</span>
                <pl-icon
                  class="blue-medium pointer"
                  [svg]="'info'"
                  [height]="18"
                  [width]="18"
                  [verticalAlign]="'-1px'"
                  (click)="toggleShowTips('schedule')"
                ></pl-icon>
              </div>
              <div class="font-h4 padding-small-t">
                <a routerLink="/schedule">View Calendar</a>
              </div>
            </div>

            <div [hidden]="!showTips.schedule" class="tip">
              Here are the students you will be seeing today.
              <a target="_blank" [href]="learnMoreAboutYourScheduleLink"
                >Learn More About Your Schedule</a
              >
            </div>
          </span>
          <div class="card">
            <span class="date" *ngIf="!loadingEvents">{{ today }}</span>
            <span class="date" *ngIf="loadingEvents">Today</span>
            <div class="scrollable">
              <ul>
                <li
                  *ngFor="let event of events"
                  [ngClass]="{ past: event.isPast, last: event.lastPast }"
                >
                  <span class="color-code {{ getColorClass(event) }}"></span>
                  <div class="description">
                    <span class="title" *ngIf="event.billing_expanded">{{
                      event.billing_expanded.name
                    }}</span>
                    <span class="detail">
                      <span class="clients">{{ event.clientList }}</span>
                      <span class="locations">{{ event.locationList }}</span>
                    </span>
                  </div>
                  <span class="time">{{ event.time }}</span>
                </li>
              </ul>
              <div class="no-events" [ngClass]="{ spaced: !loadingEvents }">
                <span *ngIf="loadingEvents">Getting your schedule...</span>
                <pl-icon
                  class="blue-medium"
                  [svg]="'happy-schedule'"
                  [height]="160"
                  [width]="240"
                ></pl-icon>
                <span class="padding-tb" *ngIf="!loadingEvents"
                  >All done for the day!</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
