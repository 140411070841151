import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// Services
// import { PLGraphQLModule } from '@root/index';
import { PLReferralService } from '@modules/locations/services';
// Store
import { PLBillingStoreModule } from './billing';
import { PLCommonEffects } from './common.effects';
import { reducer, featureKey } from './common.store';
// Shared Stores
import { FeatureFlagsEffects } from './feature-flags/feature-flags.effects';
import { PLInvoiceStoreModule } from './invoice';
import { PLProvidersStoreModule } from './providers';
import { PLSchoolYearsService, PLProviderTypesService } from '../services';

@NgModule({
  imports: [
    StoreModule.forFeature(featureKey, reducer),
    EffectsModule.forFeature([PLCommonEffects, FeatureFlagsEffects]),
    PLBillingStoreModule,
    PLInvoiceStoreModule,
    PLProvidersStoreModule,
  ],
  providers: [PLSchoolYearsService, PLProviderTypesService, PLReferralService],
})
export class PLCommonStoreModule {}

export {
  PLLoadedState,
  PLEntityState,
  PLAddManyPayload,
  setLoadedState,
  PLSelectSchoolYear,
  PLFetchCurrentSchoolYear,
  PLFetchProviderTypes,
  PLFetchReferrals,
  PLFetchCurrentSchoolYearSuccess,
  PLFetchSchoolYears,
  PLNotify,
  PLErrorNotification,
  PLSuccessNotification,
  selectSchoolYearsState,
  selectSchoolYears,
  selectCurrentSchoolYear,
  selectCurrentSchoolYearId,
  selectSelectedSchoolYear,
  selectSelectedSchoolYearId,
  selectProviderTypes,
  selectProviderTypesState,
  selectReferrals,
  selectReferralsState,
  selectProviderTypesShort,
  selectProviderTypesLong,
  selectFeatureFlags,
} from './common.store';

export {
  selectLoadedUser,
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentUserLoaded,
  selectIsServiceAndSupport,
  selectIsTechSupport,
  selectIsW2User,
  selectIsCAM,
  selectIsAccountAdmin,
} from './user.selectors';
