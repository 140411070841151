import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { PLCommonModule } from '@common/index';
import {
  PLButtonModule,
  PLDotLoaderModule,
  PLIconModule,
  PLInputModule,
  PLTableModule,
  PLTableFrameworkModule,
  PLToastModule,
  PLLodashService,
  PLModalModule,
  PLTabsModule,
} from '@root/index';

import { PLClientReferralManagerComponent } from './pl-client-referral-manager/pl-client-referral-manager.component';
import { PLClientReferralOpenComponent } from './pl-client-referral-open/pl-client-referral-open.component';
import { PLClientReferralReassignComponent } from './pl-client-referral-reassign/pl-client-referral-reassign.component';
import { PlClientReferralUnmatchComponent } from './pl-client-referral-unmatch/pl-client-referral-unmatch.component';
import { PLClientReferralsComponent } from './pl-client-referrals/pl-client-referrals.component';
import { PLClientReferralsProposeMatchesComponent } from './pl-client-referrals-propose-matches/pl-client-referrals-propose-matches.component';
import { PLClientReferralsService } from './pl-client-referrals.service';
import { PLReferralCyclesModalComponent } from './pl-referral-cycles-modal/pl-referral-cycles-modal.component';
import { PLReferralsService } from './pl-referrals.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PLButtonModule,
    PLDotLoaderModule,
    PLIconModule,
    PLInputModule,
    PLTableModule,
    PLTableFrameworkModule,
    PLToastModule,
    PLModalModule,
    PLTabsModule,
    PLCommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
  ],
  exports: [
    PLClientReferralManagerComponent,
    PLClientReferralOpenComponent,
    PLClientReferralsComponent,
  ],
  declarations: [
    PLClientReferralsComponent,
    PLClientReferralOpenComponent,
    PLReferralCyclesModalComponent,
    PlClientReferralUnmatchComponent,
    PLClientReferralManagerComponent,
    PLClientReferralsProposeMatchesComponent,
    PLClientReferralReassignComponent,
  ],
  providers: [PLLodashService, PLClientReferralsService, PLReferralsService],
})
export class PLClientReferralsModule {
  static forRoot(): ModuleWithProviders<PLClientReferralsModule> {
    return {
      ngModule: PLClientReferralsModule,
      providers: [PLClientReferralsService, PLReferralsService],
    };
  }
}
