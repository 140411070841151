<div class="modal-content">
  <div class="flex flex-col">
    <div class="flex w-full">
      <h2>Assignment no longer available</h2>
      <mat-icon class="cursor-pointer" (click)="closeModal()">close</mat-icon>
    </div>
    <div class="details">
      <div class="flex justify-center">
        <img src="assets/undraw_searching.png" alt="Description of image" />
      </div>

      <div class="w-full content">
        Unfortunately this assignment is no longer available. Refresh the page
        to update your list of available assignments.
      </div>
    </div>
    <div class="flex w-full gap-24 justify-end">
      <button
        mat-flat-button
        color="primary"
        class="save-basic-info-button"
        type="submit"
        (click)="onRefresh()"
      >
        Refresh
      </button>
    </div>
  </div>
</div>
